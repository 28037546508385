const GiftCardTypes = () => {
  return (
    <div
      className="card card-back tab-pane fade mb-5"
      id="pills-types"
      role="tabpanel"
      aria-labelledby="pills-types-tab"
    >
      <div className="col-lg-12">
        <div className="row">
          <div className="gift-text">
            <h4>
              There are two main cards on the platform; we have cards limited to
              the merchant (<span>Merchant Branded Cards</span>) while the other
              card is the unlimited universal card (
              <span>Prime e-Gift Cards</span>) which is not linked to any
              merchant.
            </h4>

            <div className="col-lg-12">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-6">
                  <h3>Prime Branded e-gift cards</h3>
                  <div className="gift-text">
                    <h4>
                      Are Universal e-gift cards can be redeemed at any
                      <span className="text-success">
                        {" "}
                        Prime e-gift card merchant participation locations
                      </span>
                      , and merchants that accept
                      <span className="text-success"> Prime-Pay</span> as form
                      of payment. We have range of cards with values from{" "}
                      <span>GHS 25 - GHS 5000</span>
                    </h4>
                  </div>
                </div>
                <div className="col-lg-6">
                  <img
                    src="assets/images/cards.png"
                    alt=""
                    className="illu-image"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row align-items-center justify-content-center mt-5">
                <div className="col-lg-6">
                  <img
                    src="assets/images/MerchantCards.jpg"
                    alt=""
                    className="illu-image"
                  />
                </div>
                <div className="col-lg-6">
                  <h3>Merchant Branded Gift Cards</h3>
                  <div className="gift-text">
                    <h4>
                      These are gift cards of merchants that are redeemable at
                      the merchant’s shop only. These cards are branded with the
                      merchant's company logo. Merchants can redeem both their{" "}
                      <span className="text-success">Branded gift cards</span>{" "}
                      and
                      <span className="text-success">
                        {" "}
                        Prime Universal e-gift cards
                      </span>
                      . Merchants’ e-gift cards value ranges from{" "}
                      <span>GHS 25 - GHS5000</span>
                      <div className="mt-3">
                        All merchants have got the Prime Pay mobile app
                        accessible on Android and IOS only. The prime pay app is
                        solely used by merchants for the redemption of gift
                        cards alone.
                      </div>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GiftCardTypes;
