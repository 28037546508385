import Footer from "./partials/Footer";

import { useState, useEffect } from "react";
import Adveritisement2 from "./shopComponents/Advertisement2";
import GiftCard from "./shopComponents/GiftCard";
import Category from "./shopComponents/Category";
import axios from "axios";
import Advertisement from "./shopComponents/Advertisement";
import NewCards from "./shopComponents/NewCards";
import PrimeCards from "./shopComponents/PrimeCards";
// import TopBrands from "./shopComponents/TopBrands";
import { Skeleton } from "@mui/material";
import FilteredCards from "./shopComponents/FilteredCards";
import CategoryCards from "./shopComponents/CategoryCards";
import PagePagination from "../others/primeusageComponent/PagePagination";
import Advertisement2 from "./shopComponents/Advertisement2";

const Shop = () => {
  const [category, setCategory] = useState([]);
  const [cards, setCards] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [page,setPage]=useState(1);
  const [pageCount,setPageCount]=useState(1);

  const handlePageClicked=(e)=>{
    setPage(e);
  }


  useEffect(() => {
    axios.get("/prime.sika/v1/cards/categories").then((res) => {
      if (res.data.status === 200 && res.data.success === true) {
        setCategory(res.data.data.categories);
      }
    });

    axios.get(`/prime.sika/v1/published-cards/one-per-merchant?page=${page}`).then((res) => {
      setCards(res.data.data.cards);
      setPageCount(res?.data?.data?.total_pages||1)
    });
  }, [page]);

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="subtitle2 text-left">Browse by category</div>
        </div>
        <div className="row">
          <div className="col col-lg-1 col-md-1 col-sm-1 category mt-2">
            <a
              href="#collapseExample"
              data-bs-toggle="collapse"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <Category
                image="/assets/images/show_all.svg"
                title="Show all"
                bg="green-gradient"
              />
            </a>
          </div>

          {category.length > 0
            ? category
                .filter((value) => {
                  if (
                    value.name
                      .toLowerCase()
                      .includes(searchTerm.toLocaleLowerCase())
                  ) {
                    return value;
                  }
                  // return value;
                })
                .slice(0, 11)
                .sort((a, b) => a.id - b.id)
                .map((item, index) => {
                  return (
                    <div
                      className="col col-lg-1 col-md-1 col-sm-1 category mt-2"
                      key={index}
                    >
                      <Category
                        image={item.logo}
                        title={item.name}
                        id={item.id}
                        setCategoryId={setCategoryId}
                      />
                    </div>
                  );
                })
            : Array(9)
                .fill("")
                .map((_, index) => (
                  <div className="shop-img-cover mx-2 mt-3" key={index} />
                ))}
        </div>
        {/* <!-- Other Icon Groups --> */}
        {searchTerm === "" ? (
          <div className="collapse" id="collapseExample">
            <div className="row mt-3">
              {category
                .slice(12)
                .sort((a, b) => a.id - b.id)
                .map((item, index) => {
                  return (
                    <div
                      className="col col-lg-1 col-md-1 col-sm-1 category mt-3"
                      key={index}
                    >
                      <Category
                        image={item.logo}
                        title={item.name}
                        id={item.id}
                        setCategoryId={setCategoryId}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row mt-3">
          <form>
            <div className="d-flex align-items-center bg-white px-2 border rounded-pill">
              <div
                className="d-flex justify-content-center align-items-center bg-green rounded-circle"
                style={{ width: "44px", height: "42px" }}
              >
                <img
                  src="/assets/images/search-normal.svg"
                  className="mx-auto img-rounded"
                  alt="search"
                />
              </div>
              <input
                type="text"
                placeholder="Search here..."
                className="form-control border-none rounded-pill search-left px-2 py-3"
                style={{ border: "none" }}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setCategoryId("");
                }}
              />
            </div>
          </form>
        </div>

        <CategoryCards
          cards={cards}
          searchTerm={searchTerm}
          categoryId={categoryId}
        />
        <FilteredCards cards={cards} searchTerm={searchTerm} />

        <div className="row">
          <div className="mt-4">
            <div className="d-flex flex-md-row flex-sm-column justify-content-between">
              <div className="shop-text-header2">
                <h2>Universal Prime eGift Cards</h2>
              </div>
              <div className="carousel slide">
                <a href="#bod" role="button" data-bs-slide="prev">
                  <i
                    className="fa fa-long-arrow-left fa-1x arrow-x-left arrow-x rounded-circle px-1 py-1 bg-white"
                    aria-hidden="true"
                  ></i>
                </a>
                &nbsp;
                <a href="#bod" role="button" data-bs-slide="next">
                  <i
                    className="fa fa-long-arrow-right fa-1x arrow-x-right rounded-circle px-1 py-1 text-white"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>

            <p className="text-left text-left1 shop-text-desc2">
              For the unlimited shopping spree, select any of our Prime eGift
              cards, Give your recepient the choice to purchase from any Prime
              participating merchant
            </p>

            <PrimeCards />
          </div>
        </div>

        
        <Advertisement2/>

        {/* <TopBrands /> */}
              
        {/* New on Prime */}
        <NewCards />

        {/* eGift Cards from top brands */}
        <div className="row">
          <div className="shop-text-header mt-4">
            <h2>More Gift Cards </h2>
          </div>
        </div>

        <div className="row">
          {cards.length > 0
            ? cards.slice(0, 12).map((item, index) => {
                return (
                  <div
                    className="col col-sm-6 col-md-2 col-lg-2  mt-2"
                    key={index}
                  >
                    <GiftCard
                      id={item.client_id}
                      image={item.image}
                      title={item.client_name}
                      client_location={item.client_location}
                      amount={`${item.client.min_card_price} - ${item.client.max_card_price}`}
                    />
                  </div>
                );
              })
            : Array(6)
                .fill("")
                .map((_, index) => (
                  <div
                    className="col col-sm-6 col-md-2 col-lg-2  mt-2"
                    key={index}
                  >
                    <div className="card card-h-1 me-2">
                      <div className="card-img">
                        <Skeleton
                          variant="rectangular"
                          width="11vw"
                          height="7vw"
                        />
                        <Skeleton variant="text" sx={{ fontSize: "0.89rem" }} />
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "0.89rem" }}
                          className="mt-2"
                        />
                      </div>
                    </div>
                  </div>
                ))}
        </div>

        <Advertisement />
        <div className="row mt-3">
          <div className="shop-text-header mt-4">
            <h2>More Gift Cards</h2>
          </div>

          {cards.length > 0
            ? cards?.map((item, index) => {
                return (
                  <div className="col col-sm-6 col-md-2 col-lg-2 mt-2" key={index}>
                    <GiftCard
                      id={item.client_id}
                      image={item.image}
                      title={item.client_name}
                      client_location={item.client_location}
                      amount={`${item.client.min_card_price} - ${item.client.max_card_price}`}
                    />
                  </div>
                );
              })
            : Array(6)
                .fill("")
                .map((_, index) => (
                  <div
                    className="col col-sm-6 col-md-2 col-lg-2  mt-2"
                    key={index}
                  >
                    <div className="card card-h-1 me-2">
                      <div className="card-img">
                        <Skeleton
                          variant="rectangular"
                          width="11vw"
                          height="7vw"
                        />
                        <Skeleton variant="text" sx={{ fontSize: "0.89rem" }} />
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "0.89rem" }}
                          className="mt-2"
                        />
                      </div>
                    </div>
                  </div>
                ))}
            <PagePagination totalPage={pageCount} setCurrentPage={handlePageClicked}/>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Shop;
