import axios from "axios";
import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { toast } from "sonner";
import VerifyCode from "./accountManagementComponents/VerifyCode";
import DeleteAccount from "./accountManagementComponents/DeleteAccount";

function AccountManagement() {
  const initialUserInput = {
    telephone: "",
  };
  const [userInput, setUserInput] = useState(initialUserInput);
  const [isLoading, setLoading] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState();
  const [otpDigit, setOtpDigit] = useState();
  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    setPhoneNumber(userInput?.telephone);
  }, [userInput?.telephone]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      telephone: userInput?.telephone,
    };
    setLoading(true);
    axios
      .post("/prime.sika/v1/customers/user-otp", data)
      .then(async (res) => {
        setLoading(false);
        let msg = res.data.data.message;
        console.log("data", res);
        toast.success(msg, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setCurrentScreen(2);
      })
      .catch((err) => {
        console.log(err.response.data?.error_msg);
        setLoading(false);
        toast.error(err.response.data?.error_msg, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <div className="container-fluid">
      <div className="col-md-12">
        <div className="container mb-5 mb-md-0">
          <div className="d-flex w-100 flex-card  pt-5">
            <h4 className="acc-font text-center">Account Deletion</h4>
            <div className="text-section">
              <div className="d-flex flex-co">
                <div className="d-flex align-items-center centy">
                  <img src="/assets/images/caution.png" alt="" className="" />
                  <h4 className="del-font">Deleting your account will:</h4>
                </div>
                <ul className="li-font">
                  <li>
                    Delete your account info(phone number, first name, last
                    name) and your profile picture
                  </li>
                  <li>Delete all your purchased gift cards.</li>
                  <li>Delete all your gifted cards</li>
                  <li>Delete all your e-Fund gift cards</li>
                  <li>Delete all your favourite merchants and cards</li>
                  <li>Delete all items in your cart</li>
                  <li>Delete all your payments and redemptions history</li>
                </ul>
              </div>
            </div>
            {currentScreen === 1 && (
              <form onSubmit={handleSubmit}>
                <div className="card card-shadow1">
                  <h4 className="phone-text">
                    Enter phone number used in creating account
                  </h4>
                  <div className="form-group d-flex">
                    <input
                      className="form-control numb-textbox"
                      type="number"
                      name="telephone"
                      value={userInput?.telephone}
                      onChange={handleUserInput}
                      required
                    />
                  </div>
                  <div className="row mt-4">
                    <div className="pad-sm">
                      <button
                        type="submit"
                        className="btn btn-danger w-100"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <div className="d-flex align-items-center justify-content-center gap-2">
                            <ClipLoader
                              color={"#fff"}
                              loading={true}
                              size={18}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                            <h4 className="cont-font">Continuing</h4>
                          </div>
                        ) : (
                          <h4 className="cont-font">Continue</h4>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
            {currentScreen === 2 && (
              <VerifyCode
                handleResendSubmit={(e) => handleSubmit(e)}
                setCurrentScreen={setCurrentScreen}
                resending={isLoading}
                phoneNumber={phoneNumber}
                setOtpDigit={(e) => setOtpDigit(e)}
              />
            )}
            {currentScreen === 3 && (
              <DeleteAccount
                phoneNumber={phoneNumber}
                otpDigit={otpDigit}
                setCurrentScreen={setCurrentScreen}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountManagement;
