import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Nav from "./components/guest/partials/Nav";
import Index from "./components/guest/Index";
import About from "./components/guest/About";
import Shop from "./components/guest/Shop";
import Business from "./components/guest/Business";
import Merchant from "./components/guest/Merchant";
import Affiliate from "./components/guest/Affiliate";
import CustomerCare from "./components/guest/CustomerCare";
import Market from "./components/guest/Market";
import ProductDetails from "./components/guest/marketComponents/ProductDetails";
import { Toaster } from "sonner";
import axios from "axios";
import CardDetailsPage from "./components/guest/shopPages/CardDetailsPage";
import { v4 as uuidv4 } from "uuid";
import OrderPreview from "./components/guest/shopPages/shopPagesComponents/OrderPreview";
import Failed from "./components/others/Failed";
import Success from "./components/others/Success";
import Terms from "./components/others/Terms";
import Privacy from "./components/others/Privacy";
import PrimeUsage from "./components/others/PrimeUsage";
import PagePagination from "./components/others/primeusageComponent/PagePagination";
import PrimePlusPrivacy from "./components/others/PrimePlusPrivacy";
import CreditAccountPage from "./components/guest/creditAccountComponents/CreditAccountPage";
import AccountManagement from "./components/guest/AccountManagement";

// axios.defaults.withCredentials = true;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Da-Secret"] = "pCweb";
if (localStorage.getItem("uuid")) {
  axios.defaults.headers.common["X-Guest-Token"] = localStorage.getItem("uuid");
} else {
  localStorage.setItem("uuid", uuidv4());
  axios.defaults.headers.common["X-Guest-Token"] = localStorage.getItem("uuid");
}

axios.defaults.baseURL = `https://api.20pesewas.com`;
// axios.defaults.baseURL=`https://8b4b-154-160-19-90.ngrok-free.app`;
// axios.defaults.baseURL = `https://api.primeegiftcard.com`;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

const App = () => {
  return (
    <div>
      <Router>
        <Toaster richColors />
        <Nav />
        <Routes>
          <Route path="/home" element={<Index />} />
          <Route path="/about" element={<About />} />
          <Route path="/business" element={<Business />} />
          <Route path="/" element={<Shop />} />
          <Route path="/shop/:id" element={<CardDetailsPage />} />
          <Route path="/card-details" element={<CardDetailsPage />} />
          <Route path="/market" element={<Market />} />
          <Route path="/account-management" element={<AccountManagement />} />
          {["/merchants/:sdc/:amt", "/merchants"].map((path, index) => {
            return <Route path={path} element={<Merchant />} key={index} />;
          })}
          {["/credit/:code", "/credit"].map((path, index) => {
            return (
              <Route path={path} element={<CreditAccountPage />} key={index} />
            );
          })}
          <Route path="/affiliate" element={<Affiliate />} />
          <Route path="/customer-care" element={<CustomerCare />} />
          <Route path="/product-details" element={<ProductDetails />} />
          <Route path="/cart" element={<OrderPreview />} />
          <Route path="/payment-failed" element={<Failed />} />
          <Route path="/payment-success" element={<Success />} />
          <Route path="/terms-of-use" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/mobile-terms" element={<Terms />} />
          <Route path="/mobile-privacy" element={<Privacy />} />
          <Route path="/primeplus-privacy" element={<PrimePlusPrivacy />} />
          <Route path="/prime-usage" element={<PrimeUsage />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
