import { Link } from "react-router-dom";

const Product = (props) => {
  return (
    <>
      <Link to="/product-details" className="no-text-decoration">
        <div className="card mx-1 mobile-card">
          <div className="card-header carousel-card-bg">
            <div className="card-img">
              <img
                src={props.image}
                width="100%"
                className="img-fluid"
                alt="product"
              />
            </div>

            <div className="stars">
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star"></span>
              <span className="fa fa-star"></span>
            </div>
            <div className="text-left shop-card-text mt-3 ">{props.title}</div>
            <div className="text-left location">
              <small>{props.location}</small>
            </div>
            <div className="d-flex flex-row justify-content-between">
              <div className="p-2 mt-2">
                <p className="text-left text-left1 product-price text-dark">
                  GHC ¢{props.price}{" "}
                </p>
              </div>
              <div className="p-2">
                <img
                  src="/assets/images/basket.png"
                  width="70%"
                  className="img-fluid"
                  alt="basket"
                />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default Product;
