import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const CustomerContactForm = () => {
  const [userInput, setUserInput] = useState({
    name: "",
    email: "",
    phone: "",
    title: "",
    message: "",
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Make api request
    const data = {
      message: userInput.message,
      title: userInput.title,
      name: userInput.name,
      telephone: userInput.telephone,
      email: userInput.email,
    };
    axios
      .post("/prime.sika/v1/contact-us/send", data)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200 && res.data.success === true) {
          Swal.fire({
            title: "Success",
            text: "Your message has successfully been sent to our customer support center. We will attend to you as soon as possible. Thank You!",
            timer: 5000,
            confirmButtonColor: "#338540",
          });

          setUserInput((userInput) => ({
            ...userInput,
            name: "",
            email: "",
            phone: "",
            title: "",
            message: "",
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // Swal.fire({
    //   title: "Success",
    //   text: "Your message has successfully been sent to our customer support center. Thank You!",
    //   timer: 5000,
    //   confirmButtonColor: "#338540",
    // });
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 mt-3">
            <label for="">
              <b>Name</b>
              <span className="text-danger">
                <sup>*</sup>
              </span>
            </label>
            <input
              type="text"
              required
              className="form-control"
              placeholder="Enter your name here"
              name="name"
              value={userInput.name}
              onChange={handleUserInput}
            />
          </div>
          <div className="col-md-6 mt-3">
            <label for="">
              <b>Email</b>
              <span className="text-danger">
                <sup>*</sup>
              </span>
            </label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter your email here"
              required
              name="email"
              value={userInput.email}
              onChange={handleUserInput}
            />
          </div>

          <div className="col-md-12 mt-3">
            <label for="">
              <b>Phone</b>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter your phone no. here"
              required
              name="phone"
              value={userInput.phone}
              onChange={handleUserInput}
            />
          </div>
          <div className="col-md-12 mt-3">
            <label for="">
              <b>Title</b>
              <span className="text-danger">
                <sup>*</sup>
              </span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Message Title"
              required
              name="title"
              value={userInput.title}
              onChange={handleUserInput}
            />
          </div>
          <div className="col-md-12 mt-3">
            <label for="">
              <b>Message</b>
              <span className="text-danger">
                <sup>*</sup>
              </span>
            </label>
            <textarea
              name="message"
              required
              value={userInput.message}
              onChange={handleUserInput}
              className="form-control"
              id=""
              cols="30"
              rows="5"
              placeholder="Type your message here"
            ></textarea>
          </div>
          <div className="col-md-12 mt-3">
            <button
              type="submit"
              className="btn btn-outline-success form-control"
            >
              Send
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CustomerContactForm;
