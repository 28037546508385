import { useState } from "react";
import Slider from "react-slick";
import Footer from "../partials/Footer";
import NewArrival from './NewArrival';

const ProductDetails = () => {
  var settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    centerMode: true,
  };
  const [activeImage, setActiveImage] = useState(
    "/assets/images/new-arrival2.png"
  );

  function toggleImage(e) {
    setActiveImage(e.target.src);
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="row">
              <div className="col-md-12">
                <img
                  src={activeImage}
                  alt="Product"
                  width="100%"
                  className="p-2"
                />
              </div>
              <div className="product-images">
                <Slider {...settings}>
                  <div className="product-image">
                    {/* <span className="product-image-overlay"> */}
                    <img alt="Product"
                      src="/assets/images/new-arrival1.png"
                      width="100%"
                      onClick={(e) => toggleImage(e)}
                    />
                    {/* </span> */}
                  </div>
                  <div className="product-image">
                    {/* <span className="product-image-overlay"> */}
                    <img
                      src="/assets/images/new-arrival2.png"
                      width="100%"
                      alt="Product"
                      onClick={(e) => toggleImage(e)}
                    />
                    {/* </span> */}
                  </div>

                  <div className="product-image">
                    {/* <span className="product-image-overlay"> */}
                    <img
                      src="/assets/images/new-arrival3.png"
                      width="100%"
                      alt="Product"
                      onClick={(e) => toggleImage(e)}
                    />
                    {/* </span> */}
                  </div>

                  <div className="product-image">
                    {/* <span className="product-image-overlay"> */}
                    <img
                      src="/assets/images/new-arrival3.png"
                      width="100%"
                      alt="Product"
                      onClick={(e) => toggleImage(e)}
                    />
                    {/* </span> */}
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-5 mt-5">
            <div className="container">
              <h3 className="product-detail-title">Product Name Here Here</h3>

              <div className="mt-3">
                <h4 className="product-detail-price text-green">GH¢ 350</h4>
              </div>

              <div className="mt-3">
                <div className="row">
                  <div className="col">
                    <span className="fa fa-star product-star me-2"></span>
                    <span className="me-3 star text-gray">5.0(458)</span>
                    <span className="fa fa-location-dot product-star me-2 text-gray"></span>
                    <span>
                      <small className="text-gray">East Lagon, Accra</small>
                    </span>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <p className="sub-text text-left">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable.
                </p>
              </div>

              <div className="row mt-3">
                <div className="col-md-3">
                  <button className="btn btn-success">Add to Cart</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="subtitle2 text-left text-black">
            <b>Merchant Info</b>
          </div>
          <div className="mt-2">
            <p className="sub-text text-left">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="subtitle2 text-left text-black">
              Location
            </div>
          </div>

          <div className="col-md-3">
            <div className="subtitle2 text-left text-black">
              Contact Info
            </div>
          </div>

          <div className="col-md-3">
            <div className="subtitle2 text-left text-black">
              Terms and Conditions
            </div>
          </div>

          <div className="col-md-3">
            <div className="subtitle2 text-left text-black">
              Return Policy
            </div>
          </div>
        </div>

        <div className="row mt-5">
            <NewArrival/>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ProductDetails;
