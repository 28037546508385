import Footer from "./partials/Footer";
// import Subscribe from "./partials/Subscribe";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import RedeemCardModal from "./merchantComponents/RedeemCardModal";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import MerchantProductImagesModal from "./merchantComponents/MerchantProductImagesModal";

const Merchant = () => {
  const settings = {
    dots: false,
    infinite: true,
    variableWidth: true,
    autoplay: true,
    arrows: false,
  };
  const sliderRef = useRef(null);
  const { sdc, amt } = useParams();
  const [merchantCategories, setMerchantCategories] = useState([]);
  const [showRedeemCardModal, setShowRedeemCardModal] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [currentScreen, setCurrentScreen] = useState(1);
  const [items, setItems] = useState([]);
  const [loadMerchant, setLoadMerchant] = useState(false);
  const [loadCats, setLoadCats] = useState(false);
  const [search, setSearch] = useState("");
  const [noMore, setNoMore] = useState(true);
  const [totalPage, setTotalPage] = useState(1);
  const [cardCode, setCardCode] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [val, setVal] = useState("");
  const [showMerchantProductImagesModal, setShowMerchantProductImagesModal] =
    useState(false);
  const [merchantDetails, setMerchantDetails] = useState([]);

  const handleMerchantProductImagesModal = (e, i) => {
    setMerchantDetails(i);
    setShowMerchantProductImagesModal(true);
  };

  const handleMerchantProductImagesModalClose = () => {
    setShowMerchantProductImagesModal(false);
  };

  const handleRedeemCardModal = (e, ele) => {
    Swal.fire({
      title: "Redeem Card?",
      text: "Redemption can only be done when you are at a merchant's shop or when a merchant is aware of the transaction",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#338540",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I'm sure",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        if (ele?.branches.length === 0) {
          setCurrentScreen(2);
        } else {
          setCurrentScreen(1);
        }
        setItems(ele);
        if (ele?.branches.length === 0 && sdc) {
          Swal.fire({
            title: "Are you sure you want to redeem with this merchant?",
            text: "A 6 digit code will be sent to the owner of the card",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#00b17d",
            cancelButtonText: "No",
          }).then((result) => {
            if (result.value) {
              axios
                .get(`/prime.sika/v1/customers/${sdc}/redemption-code`)
                .then((res) => {
                  setShowRedeemCardModal(true);
                })
                .catch((err) => {
                  Swal.fire({
                    title: "Error",
                    text: err.response.data.error_msg,
                    icon: "error",
                    button: "OK",
                  });
                  console.log(err.response);
                });
            }
          });
        } else {
          setShowRedeemCardModal(true);
        }
      }
    });
  };
  const handleRedeemCardModalClose = () => {
    setShowRedeemCardModal(false);
    setVal("");
    setCardCode("");
    setOtpCode("");
  };

  const page_limit = 10;
  const apiPath = "/prime.sika/v1/customers/merchant-category-list";

  const getMerchantList = () => {
    if (search === "") {
      let pageNo = Math.ceil(merchants.length / page_limit) + 1;
      const queryParam = "?page=" + pageNo + "&limit=" + page_limit;

      const finalUrl = apiPath + queryParam;
      axios
        .get(finalUrl)
        .then((res) => {
          setLoadMerchant(true);
          const apiRes = res?.data.data.merchants;
          const totalCount = res?.data.data.total_records;
          const mergeData = [...merchants, ...apiRes];
          setMerchants(mergeData);
          setTotalPage(totalCount);
        })
        .catch((err) => {
          setLoadMerchant(false);
          console.log(err.response);
        });
    } else {
      const queryParam = "?search_string=" + search;

      const finalUrl = apiPath + queryParam;
      setLoadMerchant(false);
      axios
        .get(finalUrl)
        .then((res) => {
          setLoadMerchant(true);

          const apiRes = res?.data.data.merchants;
          const totalCount = res?.data.data.total_records;
          setMerchants(apiRes);
          setTotalPage(totalCount);

          setNoMore(false);
        })
        .catch((err) => {
          setLoadMerchant(false);
          console.log(err.response);
        });
    }
  };
  useEffect(() => {
    getMerchantList();
  }, [search]);

  const fetchMoreDataFromApi = () => {
    if (merchants.length < totalPage) {
      getMerchantList();
    } else {
      setNoMore(false);
    }
  };

  useEffect(() => {
    axios
      .get("/prime.sika/v1/customers/merchant-categories")
      .then((res) => {
        setLoadCats(true);
        setMerchantCategories(res.data.data.merchant_categories);
      })
      .catch((err) => {
        setLoadCats(false);
        console.log(err.response);
      });
  }, []);

  const handleMerchant = (e) => {
    setLoadMerchant(false);
    const value = e;
    axios
      .get(
        `/prime.sika/v1/customers/merchant-category-list?&category_code=${value}`
      )
      .then((res) => {
        setLoadMerchant(true);
        setMerchants(res.data.data.merchants);
        setNoMore(false);
      })
      .catch((err) => {
        setLoadMerchant(false);
        console.log(err.response);
      });
  };
  useEffect(() => {
    handleMerchant();
  }, []);

  const handleViewAllMerchants = (e) => {
    setSearch("");
    setLoadMerchant(false);
    getMerchantList();
  };

  return (
    <>
      <div className="container">
        <div className="cate-pos">
          <div className="container">
            <div className="col-lg-12 mt-4">
              <div>
                <div className="d-flex justify-content-between mx-3 mx-md-0">
                  <div className="cate-font">
                    <h3 className="mt-2 mt-md-0">Explore Category</h3>
                    <h4 className="cate-statement">
                      {" "}
                      For the unlimited shopping spree, select any o f our Prime
                      eGift cards, Give your recepient the choice to purchase
                      from any Prime participating merchant
                    </h4>
                  </div>
                  <div className="float-end d-md-none">
                    <img
                      src="/assets/images/arrow-left.png"
                      alt=""
                      onClick={() => sliderRef.current.slickPrev()}
                      className="cur-pointer cur-width"
                    />
                    <img
                      src="/assets/images/arrow-right.png"
                      alt=""
                      onClick={() => sliderRef.current.slickNext()}
                      className="ms-2 cur-pointer cur-width1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row justify-content-between">
                {loadCats ? (
                  <div className="custom-col-lg-1 d-none d-md-block">
                    <div className="mt-5">
                      <img
                        src="/assets/images/leftArrow.png"
                        alt=""
                        className="cur-pointer"
                        onClick={() => sliderRef.current.slickPrev()}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="custom-col-lg-10 col-12 col-md-10">
                  {loadCats ? (
                    <>
                      <section className="mt-md-3 slider">
                        <Slider ref={sliderRef} {...settings}>
                          {merchantCategories?.map((ele, i) => {
                            return (
                              <div className="d-flex mb-3">
                                <button
                                  key={i}
                                  type="button"
                                  name="code"
                                  className="category-cover mt-md-3 me-3"
                                  value={ele?.code}
                                  onClick={() => handleMerchant(ele?.code)}
                                >
                                  <div className="category-img-cover">
                                    <img src={ele?.logo} alt="" />
                                  </div>
                                  <div className="d-block category-name-font mt-1 ms-2">
                                    <div>
                                      <h4 className="text-capitalize">
                                        {(ele?.name).toLowerCase()}
                                      </h4>
                                    </div>
                                    <div>
                                      <h5>
                                        {ele?.client_size > 75
                                          ? "75+"
                                          : ele?.client_size}{" "}
                                        merchants
                                      </h5>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            );
                          })}
                        </Slider>
                      </section>
                    </>
                  ) : (
                    <div className="no-data-div1">
                      <div className="no-data">
                        <i className="fas fa-spinner fa-spin mr-2"></i>
                      </div>
                    </div>
                  )}
                </div>
                {loadCats ? (
                  <div className="custom-col-lg-1 d-none d-md-block">
                    <div className="mt-5">
                      <img
                        src="/assets/images/rightArrow.png"
                        alt=""
                        className="cur-pointer"
                        onClick={() => sliderRef.current.slickNext()}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {loadCats ? (
                  <div className="col-lg-1 col-12 col-md-1">
                    <div className="mt-md-3">
                      <button
                        type="button"
                        className="btn btn-dark mt-md-3 category-button text-nowrap"
                        onClick={handleViewAllMerchants}
                      >
                        View All
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="row search-content-margi my-3">
              <div>
                <input
                  type="text"
                  className="form-control searchbar-cover"
                  placeholder="Search here..."
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <InfiniteScroll
            dataLength={merchants.length}
            next={fetchMoreDataFromApi}
            hasMore={noMore}
            loader={
              loadMerchant === true && (
                <div className="container">
                  <div className="row">
                    <div className="col d-flex justify-content-center my-lg-5 my-3">
                      <div class="spinner-grow text-primary m-1" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <div
                        class="spinner-grow text-secondary m-1"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <div class="spinner-grow text-success m-1" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            style={{ overflow: "hidden" }}
          >
            <div className="row justify-content-between merchant-name-font mt-3 mt-md-0">
              {loadMerchant ? (
                <>
                  {merchants.length === 0 ? (
                    <div className="no-data-div">
                      <div className="no-data">
                        <h4>No Merchant Found</h4>
                      </div>
                    </div>
                  ) : (
                    merchants.map((ele, index) => {
                      return (
                        <div className="col-lg-0 col-12" key={index}>
                          <div className="">
                            <div className="">
                              <div className="d-flex justify-content-between mt-md-5">
                                <div className="d-block">
                                  <div className="d-flex mb-md-3">
                                    <div className="merchant-logo me-3">
                                      <img
                                        src={
                                          ele?.logo_url === null
                                            ? "/assets/images/default.png"
                                            : ele?.logo_url
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <h3 className="my-auto text-capitalize">
                                      {ele?.name}
                                    </h3>
                                  </div>
                                  <div className="d-lg-flex d-block mb-md-3 justify-content-between">
                                    <div className="d-flex sm-margin-top">
                                      <img
                                        src="/assets/images/location.png"
                                        alt=""
                                        className="loc-img"
                                      />
                                      <h6 className="ms-1">{ele?.branch}</h6>
                                    </div>
                                    <div className="d-flex ms-lg-2 sm-margin-top">
                                      <img
                                        src="/assets/images/call.png"
                                        alt=""
                                        className="loc-img"
                                      />
                                      <a
                                        href={`tel:+${ele?.telephone}`}
                                        className="text-dec"
                                      >
                                        <h6 className="ms-1">
                                          {ele?.telephone}
                                        </h6>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="redeem-card-btn">
                                  <button
                                    type="button"
                                    className="btn btn-success shop-now-font text-nowrap"
                                    onClick={(e) =>
                                      handleRedeemCardModal(e, (index = ele))
                                    }
                                  >
                                    Redeem Card
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <section className="mt-md-3 slider">
                                <Slider {...settings}>
                                  {ele?.default_cover.map((image, i) => {
                                    return (
                                      <div
                                        className="card-cover cur-pointer"
                                        key={i}
                                        onClick={(e) =>
                                          handleMerchantProductImagesModal(
                                            e,
                                            (index = ele)
                                          )
                                        }
                                      >
                                        <img
                                          src={image}
                                          className="img-size"
                                          alt=""
                                        />
                                      </div>
                                    );
                                  })}
                                </Slider>
                              </section>
                            </div>
                          </div>
                          <hr />
                        </div>
                      );
                    })
                  )}
                </>
              ) : (
                <div className="no-data-div">
                  <div className="no-data">
                    <i className="fas fa-spinner fa-spin mr-2"></i>
                  </div>
                </div>
              )}
            </div>
          </InfiniteScroll>
        </div>
      </div>
      <Footer />
      <RedeemCardModal
        setItems={setItems}
        items={items}
        showRedeemCardModal={showRedeemCardModal}
        handleRedeemCardModalClose={handleRedeemCardModalClose}
        currentScreen={currentScreen}
        setCurrentScreen={setCurrentScreen}
        sdc={sdc}
        amt={amt}
        cardCode={cardCode}
        setCardCode={setCardCode}
        otpCode={otpCode}
        setOtpCode={setOtpCode}
        val={val}
        setVal={setVal}
      />
      <MerchantProductImagesModal
        handleMerchantProductImagesModalClose={
          handleMerchantProductImagesModalClose
        }
        setShowMerchantProductImagesModal={setShowMerchantProductImagesModal}
        showMerchantProductImagesModal={showMerchantProductImagesModal}
        merchantDetails={merchantDetails}
      />
    </>
  );
};

export default Merchant;
