import Footer from "../guest/partials/Footer";

const Terms = () => {
  const pStyle = {
    // marginTop: "6.0pt",
    // marginRight: "0cm",
    // marginBottom: "6.0pt",
    // marginLeft: "0cm",
    // textAlign: "justify",
  };

  const hStyle = {
    fontSize: "24.0pt",
    fontFamily: "SF UI Display Normal",
    color: "#333333",
    letterSpacing: ".75pt",
  };

  const spanStyle = {
    fontSize: "13.5pt",
    fontFamily: "SF UI Display Normal",
    color: "#333333",
  };
  return (
    <>
      <div className="container p-4">
        <p style={pStyle} className="sub-heading">
          <span
            lang="EN-US"
            style={{
              fontSize: "2rem",
              // fontFamily:"SF UI Display Normal",sans-serif;
              color: "#333333",
              letterSpacing: ".75pt",
            }}
          >
            Terms of Service
          </span>
        </p>

        <p style={pStyle}>
          <span lang="EN-US" style={hStyle} className="sub-heading">
            Participating Merchant Agreement
          </span>
        </p>

        <p
        
          style={{ marginBottom: "11.25pt", textAlign: "justify" }}
        >
          <span lang="EN-US" style={spanStyle}>
            As of 09/01/2020
          </span>
        </p>

        <p style={pStyle}>
          <span lang="EN-US" style={hStyle}>
            Section 1: Introductory Provisions
          </span>
        </p>

        <p
        
          style={{ marginBottom: "11.25pt", textAlign: "justify" }}
        >
          <span lang="EN-US" style={spanStyle}>
            This agreement (&quot;Agreement”) is entered into by Prime, its
            subsidiaries, and affiliates (&quot;Prime”) and (&quot;Merchant”).
            The Agreement is effective as of the date it is fully signed or
            otherwise executed by both parties (&quot;Effective Date”).
          </span>
        </p>

        <p
        
          style={{ marginBottom: "11.25pt", textAlign: "justify" }}
        >
          <span lang="EN-US" style={spanStyle}>
            Prime offers an electronic gift card service (&quot;Prime Service”)
            through its website and other hosted technology primarily located at
            www.Prime.com (&quot;Site”). The Prime Service allows merchants to
            create, sell to its users, track and market electronic gift cards to
            be used by the recipient at a specific merchant (&quot;Participating
            Merchant Gift Card&quot;). Merchant wishes to participate in the
            Prime service. Accordingly, Prime and Merchant agree as follows:
          </span>
        </p>

        <p style={pStyle}>
          <span lang="EN-US" style={hStyle}>
            Section 2: Definitions
          </span>
        </p>

        <p
        
          style={{ marginBottom: "11.25pt", textAlign: "justify" }}
        >
          <span lang="EN-US" style={spanStyle}>
            For purposes of this Agreement, the terms below shall have the
            following meanings:
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              2.1 &quot;CONFIDENTIAL INFORMATION”
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            “Confidential Information” means the terms of this Agreement, User
            Data, and any other information shared with Merchant by Prime in
            connection with the Prime Service.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              2.2 &quot;PRIME CONTENT”
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            &quot;Prime Content” means any content owned or licensed by Prime,
            including such content provided by Prime to Merchant for use in
            connection with the Prime Service, including, but not limited to,
            trademarks, service marks, logos, domain names, trade names,
            graphics, images, text, software, and copyrightable material.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              2.3 &quot;PRIME USER”
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            &quot;Prime User” means any user of the Prime Service, including the
            purchaser or recipient of a Prime eGift card.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              2.4 &quot;MERCHANT CONTENT”
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            &quot;Merchant Content” means any content owned or licensed by
            Merchant (other than from Prime), including such content provided by
            Merchant to Prime for use in connection with the Prime Service,
            including, but not limited to, trademarks, service marks, logos,
            domain names, trade names, graphics, images, text, software, and
            copyrightable material.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              2.5 &quot;USER DATA”
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            “User Data” means any and all information relating to a Prime User
            including, but not limited to, name, address, email address,
            telephone number, other contact information, financial information,
            information relating purchases or preferences, and any other
            identifying information.
          </span>
        </p>

        <p style={pStyle}>
          <span lang="EN-US" style={hStyle}>
            Section 3: Merchant Participation in the Prime Service
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              3.1 ACCEPTANCE AS A PARTICIPATING MERCHANT
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Subject to Merchant’s execution of this Agreement and Prime’s
            acceptance thereof, Merchant shall be permitted to participate in
            the Prime Service during the term of this Agreement. Merchant shall
            comply with all terms of this Agreement and the Prime Service. Prime
            shall have no obligation to issue any Participating Merchant Gift
            Cards until Merchant has been permitted to participate.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              3.2 PAYMENT TERMS
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Upon the purchase of a Participating Merchant Gift Card, successful
            delivery to the recipient, and receipt of the funds from the Prime
            User (&quot;Purchase”), Prime will initiate the transfer one hundred
            (100%) percent of the total value of the Participating Merchant Gift
            Card less normal payment processing cost and, if applicable, any
            Prime Convenience Fees two business (2) days (&quot;Payout Period”)
            after purchase into the Participating Merchant’s designated Momo or
            bank account, respectively.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            As part of the sign-up process Merchants are not required to
            immediately assign a Bank account for funding. Prime will notify the
            Merchant by email and/or subsequent phone messages that its first
            funds are available for deposit and that in order to be funded the
            Merchant must immediately link a Bank account. Any Merchant that has
            not designated a linked Bank Account for funding within 60 days
            after the first funds are available for deposit will be issued a
            check addressed to the Merchant address on file for a ¢50 processing
            fee.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            To protect against illegal or detrimental activity and to protect
            merchants and consumers, Prime reserves the right, at its sole
            discretion, to withhold amounts in certain circumstances including,
            but not limited to, frequent purchases for a single recipient using
            a single debit card and/or large amounts outstanding for a Merchant
            and/or whereby Merchants are not easily identifiable as legal
            entities with ongoing transparent business operations.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              3.3 PRIME CONVENIENCE FEES ON PURCHASES OTHER THAN THE PRIME APP
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Merchants have the option at any time to either: (i) pass along the
            Convenience Fee to the buyer's as reflected in the total purchase
            price paid upon checkout (at no charge to the Merchant) or (ii)
            accept the Convenience Fee which will be automatically deducted from
            a Purchase and reflected in net proceeds delivered to the Merchant's
            designated bank account. The option to determine who pays the
            Convenience Fee can be found within a Merchant's Dashboard in the
            Settings section, Payouts tab.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            There is no Convenience Fee assessed to merchants creating and
            distributing promotional gift card on the merchant dashboard.
            However, any merchant gift card Purchases as a result of a
            promotional gift card offering on the platform will be assessed a
            Convenience Fee.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              3.4 PRIME CONVENIENCE FEES ON PURCHASES USING THE PRIME Gift card
              APP
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            e-Gift card purchase on the app attracts 
          </span>
        </p>

        <div className="col-md-6">
          <table className="table table-bordered">
            <tr
              style={{
                width: "47.15pt",
                padding: "4.1pt 4.5pt 0cm 4.5pt",
                height: "19.5pt",
              }}
            >
              <th>S/No.</th>
              <th>TRANSACTION TYPE</th>
              <th>COST</th>
            </tr>
            <tr>
              <td>1</td>
              <td>MoMo settlement fees</td>
              <td>2%</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Cards settlement fees</td>
              <td>2.5%</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Prime Convenience Fee</td>
              <td>1%</td>
            </tr>
          </table>
        </div>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Settlement fee shall apply at T+1 i.e transaction time plus one
            working day. 
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              3.5 Card Promotions{" "}
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Prime may assess an additional fee of 10% of card value for each
            promotional card per month.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              3.6 TAXES
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Merchant is responsible for determining what, if any, taxes apply to
            the redemption of Participating Merchant Gift Cards and for
            collecting, reporting, and remitting the correct tax to the
            appropriate tax authority, if any. Prime is not responsible for
            determining whether taxes apply or for collecting, reporting, or
            remitting any taxes arising from any transaction with or by
            Merchant.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              3.7 MODIFICATION OF PRIME SERVICE TERMS
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Prime reserves the right, in its sole discretion, to modify the
            terms of the Prime Service and Merchant’s participation therein.
            Such modifications shall be binding on Merchant upon written notice
            from Prime in accordance with the notice provisions in Section 9
            below. If Merchant notifies Prime in writing that it rejects such
            modifications, this Agreement shall automatically terminate.
            Termination shall be effective on the tenth business day following
            the date of delivery of the notice as provided in Section 9 below.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              3.8 LICENSE TO USE PRIME CONTENT
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Once accepted into the Prime Service, Merchant shall be permitted
            access to the Prime application used to process Participating
            Merchant Gift Cards (&quot;Application”). In addition, Prime may
            provide other Prime Content to Merchant in connection with the Prime
            Service. Prime hereby grants Merchant a limited, nonexclusive,
            nontransferable, nonsublicensable, revocable license to use the
            Application and other Prime Content solely in connection with the
            Prime Service during the term of this Agreement.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              3.9 LICENSE TO USE MERCHANT CONTENT
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Merchant may provide Merchant Content to Prime for use in connection
            with the Prime Service. Merchant hereby grants Prime a nonexclusive,
            worldwide, royalty free license to use such Merchant Content in
            connection with the Prime Service.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              3.10 COMPLIANCE WITH APPLICABLE LAW
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Merchant will comply with the Participating Merchant Gift Card terms
            and conditions, as set forth in Prime’s Terms of Service, available
            on the Site, and with all state, and other laws applicable to gift
            cards. Participating Merchant Gift Cards do not expire, do not incur
            service or maintenance fees to the purchaser or recipient for any
            reason, are not redeemable for cash unless required by law, and will
            not be replaced by Prime if lost or stolen unless required by law.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Merchant will allow the recipient of a Participating Merchant Gift
            Card to redeem the full value of the card. Merchant is responsible
            for keeping track of the value and any unredeemed balance. Merchant
            is solely responsible for complying with any applicable escheat or
            abandoned or unclaimed property laws.
          </span>
        </p>

        <p style={pStyle}>
          <span lang="EN-US" style={hStyle}>
            Section 4: Confidentiality; Privacy; and Data Security
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              4.1 CONFIDENTIAL INFORMATION
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Confidential Information provided to Merchant pursuant to this
            Agreement is provided solely for use in connection with the Prime
            Service. Merchant may not use Confidential Information for any other
            purpose. Merchant agrees to take reasonable steps to safeguard the
            confidentiality of all Confidential Information and not to disclose
            Confidential Information to any other person or entity except as
            permitted under this Agreement or with Prime’s prior written
            consent.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              4.2 PRIVACY AND DATA SECURITY
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            All User Data relating to Prime Users provided by Prime, or its
            agents or partners, to Merchant in connection with the Prime Service
            shall be used by Merchant solely for the purpose of participating in
            the Prime Service. Merchant may not use User Data for any other
            purpose or combine or associate User Data with any other data it
            retains about a natural person. Merchant agrees that it will not
            share, transfer, or transmit User Data to any other person or entity
            except as permitted under this Agreement or with Prime’s prior
            written consent. Merchant is solely responsible for compliance with
            any laws and regulations applicable to User Data while it is in
            Merchant’s possession, custody, or control. All User Data is subject
            to Prime’s privacy practices as described in its Privacy Policy,
            available on the Site.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              4.3 MATERIAL BREACH; INJUNCTIVE RELIEF
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Any breach of this section shall be considered a material breach
            resulting in irreparable harm to Prime for which there is no
            adequate remedy at law. In the event of such a breach, Prime shall
            be entitled to injunctive relief in addition to any other
            appropriate relief.
          </span>
        </p>

        <p style={pStyle}>
          <span lang="EN-US" style={hStyle}>
            Section 5: Merchant Representations and Warranties
          </span>
        </p>

        <p
        
          style={{ marginBottom: "11.25pt", textAlign: "justify" }}
        >
          <span lang="EN-US" style={spanStyle}>
            Merchant represents and warrants to Prime, now and throughout the
            term of the Agreement, as set forth below. Merchant will notify
            Prime immediately if any of Merchant’s representations or warranties
            becomes inaccurate or untrue in any material respect during the term
            of the Agreement.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              5.1 AUTHORITY; QUALIFICATION TO DO BUSINESS
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Merchant has the right and authority to enter, and perform its
            obligations under, the Agreement. Merchant is qualified to do
            business and is in good standing in each jurisdiction where the
            conduct of its business requires such qualification.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              5.2 TAXES
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Merchant is registered for sales and other tax collection purposes
            in each jurisdiction in which Merchant’s goods or services will be
            provided.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              5.3 MERCHANT CONTENT
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Merchant owns all right, title, and interest in the Merchant Content
            and has the right to grant the licenses provided in this Agreement.
            The Merchant Content does not violate any applicable laws or
            infringe any copyright, trademark, other intellectual property, or
            any other right of any third party.
          </span>
        </p>

        <p style={pStyle}>
          <span lang="EN-US" style={hStyle}>
            Section 6: Disclaimers; Limitation of Liability; Force Majeure
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              6.1 DISCLAIMERS
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            The Prime service, site, and all related content and materials are
            provided on an “as is” basis. To the fullest extent permitted by
            law, they are provided without warranties of any kind, express or
            implied, including but not limited to warranties of merchantability,
            fitness for a particular purpose, title and non-infringement, and
            any warranties arising from course of dealing or usage of trade.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Prime does not warrant that the Prime service, site, or related
            content and materials is accurate, complete, reliable, current, or
            error-free, that it will meet your requirements, will be available
            at any particular time or location or without interruption, that
            defects or errors will be corrected, or that the site is free of
            viruses or other harmful components. Use of the Prime service and
            site is at merchant’s own risk and merchant is solely responsible
            for any loss or damage to property or data that may result.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Prime does not warrant, endorse, guarantee, or assume responsibility
            for any product or service purchased using a Prime eGift card, or
            any product or service advertised or offered by a third party
            through the site or the Prime service or through any hyperlinked
            website. Prime is not a party to any transaction between a Prime
            user and a participating merchant or any third-party provider of
            products or services.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              6.2 LIMITATION OF LIABILITY
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            In no event shall Prime be liable for any direct, indirect,
            consequential, incidental, special, punitive, or exemplary damages,
            including but not limited to damages for loss of profits, goodwill,
            or use of property or data resulting from the use, inability to use,
            or unavailability of the Prime service, or any damages resulting
            from hacking, tampering, or other unauthorized access to or use of
            the site or the Prime service.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            In addition, Prime’s total cumulative liability shall be limited to
            the amount actually paid by merchant to Prime during the three-month
            period immediately preceding the date on which the claim arose.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            This limitation of liability applies to the fullest extent permitted
            by law regardless of the type of claim, whether based on contract,
            tort, negligence, strict liability, or on any other basis, and even
            if Prime has been advised of the possibility of such damage.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            If state laws do not allow limitations on implied warranties or the
            exclusion or limitation of certain damages. If these laws apply,
            some or all of the above disclaimers, exclusions, or limitations may
            not be applicable.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              6.3 FORCE MAJEURE
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            In addition to, and without limiting, the Limitation of Liability
            section above, Prime shall not be liable for any damage, loss, delay
            in performance, or nonperformance resulting from conditions beyond
            its reasonable control, including but not limited to, government
            action, acts of terrorism, acts of war, acts of third parties,
            strikes, riots or other civil disturbances, accidents, fire, floods,
            earthquakes and other natural disasters, power outages, and
            communications and other system failures.
          </span>
        </p>

        <p style={pStyle}>
          <span lang="EN-US" style={hStyle}>
            Section 7: Remedies for Breach; Indemnification
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              7.1 REMEDIES FOR BREACH
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            A &quot;Material Breach” includes, but is not limited to, any breach
            by Merchant of Sections 3, 4, or 5 of this Agreement. In the event
            of a Material Breach, Merchant shall be obligated to reimburse Prime
            for any damages suffered by Prime, including any actual, incidental,
            and consequential damages.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              7.2 INDEMNIFICATION
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Merchant agrees to indemnify and hold harmless Prime, and its
            officers, directors, employees, representatives, affiliates,
            successors, and assigns, against any and all claims by a third party
            relating to Merchant’s participation in the Prime Service including,
            but not limited to, claims relating to: (a) any actual or alleged
            breach of this Agreement by Merchant, including breach of the
            representations and warranties herein; (b) any claim for sales tax
            or any other tax obligation arising from the issuance or redemption
            of a Participating Merchant Gift Card or the provision of goods
            and/or services in connection therewith; (c) any claim by any local,
            or state government entity for unredeemed Participating Merchant
            Gift Cards or unredeemed cash values thereof, or any other amounts
            under any applicable escheat or abandoned or unclaimed property
            laws; (d) any failure of Merchant to honor a Participating Merchant
            Gift Card; (e) any infringement of any patent, trademark, copyright,
            publicity, privacy, trade secret, or other right of any third party
            by Merchant, including, without limitation, in connection with the
            Merchant Content or Prime’s use thereof; (f) Merchant’s provision of
            goods, services, or information in connection with the Prime
            Service; and (g) Merchant’s use or handling of User Data.
          </span>
        </p>

        <p style={pStyle}>
          <span lang="EN-US" style={hStyle}>
            Section 8: Term and Termination; Survival
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              8.1 TERM
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            This Agreement shall commence on the Effective Date and continue
            until terminated as provided herein.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              8.2 TERMINATION
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Prime may terminate Merchant’s participation in the Prime Service at
            any time and for any reason by providing Merchant written notice of
            such termination. Termination shall be effective on the date of
            delivery of the notice as provided herein.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Prime may, in its sole discretion, and without liability to Merchant
            or any third party, terminate the Site or Prime Service at any time,
            for any reason, and without prior notice. This Agreement shall
            terminate upon termination of the Prime Service.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Merchant may terminate participation in the Prime Service at any
            time by providing Prime prior written notice at least ten (10)
            business days prior to termination. Merchant may separately delete
            the Prime e-gift card App without any prior written notice to
            terminate the use of the Prime App only. Termination of the Prime
            Service shall be effective on the tenth business day following the
            date of delivery of the notice as provided herein. Termination of
            the Prime Clover App will be immediate.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            A Merchant’s voluntary termination of the Prime Service (either
            written and/or by deleting the Prime App) or involuntary termination
            by Prime does not release the Merchant from any liability or
            obligations as a result of prior gift cards sold and that remain
            outstanding, prior promotion campaigns and/or fundraiser programs
            that remain outstanding as a result of using the Prime Service.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            This Agreement may terminate automatically as provided in Section 3
            above.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              8.3 RIGHTS AND OBLIGATIONS UPON TERMINATION; SURVIVAL
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Except as provided otherwise herein, all rights granted to Merchant
            under this Agreement shall cease immediately upon termination. Prime
            shall not be obligated to issue any Participating Merchant Gift
            Cards on behalf of Merchant after termination. Merchant shall remain
            obligated to honor all Participating Merchant Gift Cards issued on
            behalf of Merchant prior to termination and to pay any amounts owed
            to Prime under this Agreement.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            This section and the following additional sections shall survive
            termination: 2, 3, 4, 5, 6, 7, 9, 10, and 11.
          </span>
        </p>

        <p style={pStyle}>
          <span lang="EN-US" style={hStyle}>
            Section 9: Notices
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              9.1 FORM OF NOTICE; METHOD OF DELIVERY
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Whenever any notice is permitted or required under this Agreement,
            it shall be in writing and deemed delivered when: (a) delivered by
            electronic mail to a party at the email address provided below; or
            (b) actually received or rejected by a party or, if earlier and
            regardless of whether actually received or not, when deposited in
            (i) Ghanapost mail, postage prepaid, certified mail, return receipt
            requested or (ii) a regional or national overnight courier service,
            addressed to the party at the address provided below.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              9.2 NOTICES TO PRIME
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Notices to Prime must be sent by email to info@primergift.com, or by
            one of the forms of mail delivery service described above to: Prime,
            P.O.Box AJ 75, Alajo, Accra.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              9.3 NOTICES TO MERCHANT
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Notices to Merchant must be sent by email to info@primergift.com ,
            or by one of the forms of mail delivery service described above to:
            Prime, P.O.Box AJ 75, Alajo, Accra, Attn: Legal.
          </span>
        </p>

        <p style={pStyle}>
          <span lang="EN-US" style={hStyle}>
            Section 10: Governing Law; Venue
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              10.1 GOVERNING LAW
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            These Terms shall be governed and construed in accordance with
            applicable federal law and the laws of the Republic of Ghana,
            without regard to its conflict of laws provisions or any other
            principles that may provide for the application of the laws of
            another jurisdiction.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              10.2 VENUE
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            You agree that any action at law or in equity relating to this
            Agreement shall be filed only in Accra High Court, and you hereby
            irrevocably and unconditionally consent and submit to the exclusive
            jurisdiction of such courts in any suit, action, or proceeding
            arising out of these Terms.
          </span>
        </p>

        <p style={pStyle}>
          <span lang="EN-US" style={hStyle}>
            Section 11: Miscellaneous
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              11.1 INDEPENDENT CONTRACTORS
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            The parties are independent contractors. Nothing in this Agreement
            shall be deemed or construed by the parties, nor by any third party,
            as creating a joint venture, partnership, franchise, or agency
            relationship between the parties. Neither party has the authority,
            without the other party’s prior written approval, to bind or commit
            the other party in any way.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              11.2 NO THIRD PARTY BENEFICIARIES
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            This Agreement is not intended, and shall not be construed, to
            create any rights or remedies in any other party, and no other party
            shall assert any rights as a third party beneficiary under this
            Agreement.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              11.3 ASSIGNMENT
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Merchant may not transfer or assign, by operation of law or
            otherwise, any rights or obligations under this Agreement without
            Prime’s prior written consent, and any attempted transfer or
            assignment without such consent shall be void. Prime may freely
            transfer or assign its rights and obligations under this Agreement
            to any third party without notice or consent. Subject to the
            foregoing, this Agreement shall be binding on and inure to the
            benefit of the parties and their respective successors and permitted
            assigns.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              11.4 WAIVER
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            The failure of a party to exercise any right under, or to enforce
            any provision of, this Agreement shall not constitute a waiver of
            such right or provision. Whenever consent to, or approval of, an act
            by one party is required from the other party, the grant of such
            consent or approval shall not be deemed to waive or render
            unnecessary consent to or approval of any subsequent similar act.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              11.5 SEVERABILITY
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            If any provision of this Agreement is declared invalid by a court of
            competent jurisdiction, the remaining provisions shall not be
            affected and shall be interpreted and enforced as if the invalid
            provision had not been included. Upon such declaration of
            invalidity, the parties will negotiate in good faith to modify the
            Agreement to effect the original intent of the parties as closely as
            possible so that the transactions contemplated under the Agreement
            are fulfilled.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              11.6 ENTIRE AGREEMENT
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Subject to Sections 3.7 and 4.2, this Agreement sets forth the
            entire agreement between the parties relating to Merchant’s
            participation in the Prime Service and supersedes any prior or
            contemporaneous understandings or agreements.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              11.7 AMENDMENTS
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Except as expressly permitted herein, amendments to this Agreement
            must be in writing and signed by both parties.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              11.8 INTERPRETATION OF TERMS
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Both parties have had the opportunity to consult with their
            respective attorneys regarding the terms of this Agreement and are
            entering into the Agreement voluntarily. No presumption of
            interpretation shall be made against either party as drafter of the
            Agreement.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              11.9 COUNTERPARTS
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            This Agreement may be executed in counterparts with separate
            signature pages, which together shall constitute a single document.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              11.10 HEADINGS
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Headings are for convenience only and in no way limit or otherwise
            affect the meaning or scope of any provision. Whenever used herein,
            the singular shall include the plural and the plural shall include
            the singular.
          </span>
        </p>

        <p
        
          style={{
            marginTop: "15.0pt",
            marginRight: "0cm",
            marginBottom: "7.5pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
            textIndent: "-18.0pt",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "10.0pt",
              fontFamily: "Symbol",
              color: "#1E95D8",
              textTransform: "uppercase",
              letterSpacing: ".75pt",
            }}
          >
            ·
            <span style={{ fontSize: "7.0pt", fontFamily: "Times New Roman" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "10.5pt",
                fontFamily: "SF UI Display Normal",
                color: "#1E95D8",
                textTransform: "uppercase",
                letterSpacing: ".75pt",
              }}
            >
              11.11 ELECTRONIC COMMUNICATIONS
            </span>
          </b>
        </p>

        <p
        
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "22.5pt",
            textAlign: "justify",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            Merchant agrees that business with Prime may be done electronically
            and that the provision of notices, disclosures, and other
            information electronically satisfies any legal requirement that such
            communications be in writing.
          </span>
        </p>

        <p style={pStyle}>
          <span
            lang="EN-US"
            style={{
              fontSize: "13.5pt",
              fontFamily: "SF UI Display Normal",
              color: "#333333",
            }}
          >
            The parties may make agreements by electronic means, and this
            Agreement has the same legal effect as an agreement entered into on
            paper. By checking the box and providing the electronic
            identification below, you agree to the terms and conditions in this
            Agreement.
          </span>
        </p>

        <p style={{ textAlign: "justify" }}>
          <span lang="EN-US">&nbsp;</span>
        </p>
      </div>
      <Footer/>
    </>
  );
};

export default Terms;
