import { useRef } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Slider from "react-slick";

const MerchantProductImagesModal = ({
  showMerchantProductImagesModal,
  handleMerchantProductImagesModalClose,
  merchantDetails,
}) => {
  const settings = {
    dots: false,
    infinite: true,
    variableWidth: true,
    autoplay: true,
    arrows: false,
  };
  const [index, setIndex] = useState(0);
  const handleSlider = useRef();
  const changeProductImage = (e, i) => {
    setIndex(i);
  };
  return (
    <Modal
      show={showMerchantProductImagesModal}
      onHide={handleMerchantProductImagesModalClose}
    >
      <Modal.Header closeButton>
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex">
            <div className="name-font-size">
              <h4 className="mt-3 text-capitalize">
                {merchantDetails?.name} product images
              </h4>
            </div>
          </div>
          <div>
            <button onClick={handleMerchantProductImagesModalClose}>
              <img src="/assets/img/close-btn.png" alt="" />
            </button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="col-lg-12">
          <div className="product-image-cover">
            <img
              src={merchantDetails?.default_cover?.[index]}
              alt=""
              className=""
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-10 col-8"></div>
            <div className="col-md-2 col-4">
              <div className="d-flex justify-content-between mt-3 mt-md-4">
                <div>
                  <img
                    src="/assets/images/leftArrow.png"
                    alt=""
                    className="cur-pointer"
                    onClick={() => handleSlider.current.slickPrev()}
                  />
                </div>
                <div>
                  <img
                    src="/assets/images/rightArrow.png"
                    alt=""
                    className="mt-1 cur-pointer"
                    onClick={() => handleSlider.current.slickNext()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="">
            <section className="slider">
              <Slider {...settings} ref={handleSlider}>
                {merchantDetails?.default_cover?.map((image, i) => {
                  return (
                    <div
                      className="product-card-cover cur-pointer"
                      key={i}
                      onClick={(e) => changeProductImage(e, i)}
                    >
                      <img
                        src={image}
                        className={
                          index === i
                            ? "product-img-size clicked"
                            : "product-img-size"
                        }
                        alt=""
                      />
                    </div>
                  );
                })}
              </Slider>
            </section>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default MerchantProductImagesModal;
