import Footer from "../guest/partials/Footer";
import { useNavigate } from 'react-router-dom';

const Success = () => {

    const navigate=useNavigate();
  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <img src="/assets/images/success.png" width="50%" className="img-fluid" alt="Success" />
        <h5 className="text-success diplay-5">
          Payment Completed Successfully
        </h5>
        <button
          className="btn btn-success btn-block mt-3"
          style={{ backgroundColor: "#118c13" }}
          id="closeBtn"
          onClick={()=>navigate('/')}
        >
          Close
        </button>
      </div>
      <Footer/>
    </>
  );
};

export default Success
