const RedeemGiftCards = () => {
  return (
    <div
      className="card card-back tab-pane fade mb-5"
      id="pills-redeem"
      role="tabpanel"
      aria-labelledby="pills-redeem-tab"
    >
      <div className="col-lg-12">
        <div className="row">
          <div className="gift-text">
            <h4>
              At the moment, cards can only be redeemed at the merchant’s shop
              and also remote. There are 3 ways to redeem a card on the platform
              at the moment. A purchased card can be redeemed using QR code of
              the merchant, Qr code of the customer or 16-digit code of the
              purchased card. Below describes the redemption flows into details:
            </h4>
            <ul className="ul-text-color mt-3" type="square">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <li>
                      <span>Redeeming Card Using Merchant’s QR Code</span> :
                      Every merchant has a QR Stand at their shops to receive
                      payment. The QR code is unique to each merchant and
                      therefore one merchant's QR code cannot be used for
                      another. Check the steps below when you find yourself at
                      the merchants shop and wants to redeem your gift card:
                      <ol className="mt-2">
                        <li>
                          Customer goes to his mobile app and clicks on{" "}
                          <span>Wallet</span> to see all his purchased cards
                        </li>
                        <li>
                          Customer selects particular card from his wallet to
                          redeem
                        </li>
                        <li>
                          Customer has the option to redeem all of the amount on
                          the card or can enter an amount he/she wants to
                          redeem.
                        </li>
                        <li>
                          Customer clicks on <span>Redeem Card</span> Button and
                          proceed to redeem card
                        </li>
                        <li>
                          Customer clicks on <span>Scan Qr Code</span> on the
                          next page which opens the camera on his/her device.
                        </li>
                        <li>
                          Customer makes sure the Qr code of the merchant is
                          positioned properly in the camera’s frame to easily
                          capture and redeem the card.
                        </li>
                        <li>
                          When redemption is successful, a receipt is generated
                          containing details of redemption which is sent to the
                          customer via SMS and app Notification. The merchant
                          also gets notified via email and SMS to his associated
                          phone number.
                        </li>
                      </ol>
                    </li>
                    <li className="mt-5">
                      <span>Redeeming Card Using 16-digit Code of Card</span> :
                      Every gift card on Prime platform has got a 16-digit code.
                      This code can be sent or given to a merchant to do
                      redemption in cases where they have to do delivery to you.
                      <ol className="mt-2">
                        <li>
                          There are two ways customer gets access to the
                          16-digit code of a purchased card
                        </li>
                        <li>
                          <span>User has to login</span> and clicks on{" "}
                          <span>Wallet</span> to see all his purchased cards
                        </li>
                        <li>
                          Customer selects particular card from his wallet to
                          redeem
                        </li>
                        <li>
                          Customer clicks on <span>Reveal Code</span> button on
                          the next page
                        </li>
                        <li>
                          User authenticates his security PIN or biometrics
                          [Face Id/ FingerPrint] before the 16-digit code is
                          revealed.
                        </li>
                        <li>
                          User then mentions his 16-digit code to the merchant
                          and the merchant inputs this code on the{" "}
                          <span>Prime Pay app.</span>
                        </li>
                        <li>
                          If the 16-digit code of the card is valid, the
                          merchant’s app (<span>Primepay</span>) moves to the
                          next screen where it asks for the <span>amount</span>{" "}
                          to be redeemed by the customer.
                        </li>
                        <li>
                          Customer tells merchant how much he/she wants to
                          redeem and merchant inputs the amount and clicks on
                          redeem.
                        </li>
                        <li>
                          A 6-digit code is generated and sent via SMS to the
                          owner of the card, in this case the customer, for
                          verification and authentication purposes.
                        </li>
                        <li>
                          The customer gives back the 6-digit code to the
                          merchant for verification.
                        </li>
                        <li>
                          If there is a successful verification of the 6-digit,
                          the redemption process starts.
                        </li>
                        <li>
                          At this stage the system verifies if the card has
                          enough balance to redeem the amount entered by the
                          merchant.
                        </li>
                        <li>
                          When redemption is successful, a receipt is generated
                          containing details of redemption which is sent to the
                          customer via SMS and app Notification. The merchant
                          also gets notified via email and SMS to his associated
                          phone number.
                        </li>
                        <li>
                          <span>When the user does not have the app,</span> and
                          was gifted the card from someone, the user now becomes
                          the owner of the card and receives an SMS containing
                          the 16-digit code of the card.
                        </li>
                        <li>
                          This user can also go to the merchant's shop to redeem
                          the card with the 16-digit code following the above
                          stated steps from point No. 6.
                        </li>
                      </ol>
                    </li>
                  </div>
                  <div className="col-lg-6">
                    <img
                      src="/assets/images/Redeeming-a-card.png"
                      alt=""
                      className="redeem-h d-none d-lg-block"
                    />
                  </div>
                </div>
              </div>
              <li className="mt-5">
                <span> Redeeming Card Using Qr-Code of Gift Card :</span>
                <ol className="mt-2">
                  <li>
                    Customer goes to his mobile app and clicks on{" "}
                    <span>Wallet</span> to see all his purchased cards
                  </li>
                  <li>
                    Customer selects particular card from his wallet to redeem
                  </li>
                  <li>
                    Customer has the option to redeem all of the amount on the
                    card or can enter an amount he/she wants to redeem.
                  </li>
                  <li>
                    Customer clicks on <span>Redeem</span> Button and proceed to
                    redeem card
                  </li>
                  <li>
                    Customer clicks on the <span>Generate Qr Code</span> button
                    on the next page pops up an authentication dialog.
                  </li>
                  <li>
                    User then authenticates his security PIN or biometrics [Face
                    Id/ FingerPrint] and the 16-digit.
                  </li>
                  <li>
                    After successful authentication, a QR code is generated
                    using the amount of the card or the amount entered.
                  </li>
                  <li>
                    Merchants can now use the <span>Primepay</span> app to scan
                    this qr-Code of the card.
                  </li>
                  <li>
                    When scanning is successful, the card is automatically
                    redeemed.
                  </li>
                  <li>
                    When redemption is successful, a receipt is generated
                    containing details of redemption which is sent to the
                    customer via SMS and app Notification. The merchant also
                    gets notified via email and SMS to his associated phone
                    number. At the moment pdf generated receipt is being worked
                    on which will be sent via app and SMS for download to both
                    customer and merchant.
                  </li>
                </ol>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RedeemGiftCards;
