import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Advertisement = () => {
  const settings = {
    infinite: true,
    autoplay: true,
    variableWidth: true,
    speed: 700,
  };

  return (
    <div className="row">
      <div className="mt-3 d-none d-sm-none d-md-block d-lg-block">
        <div className="col-md-12">
          <section className="slider variable">
            <Slider {...settings}>
              <div>
                <img
                  src="/assets/images/ads/new1.jpeg"
                  width="100%"
                  alt=""
                  className="sli-img"
                />
              </div>
              
              <div>
                <img
                  src="/assets/images/ads/ad-1.jpeg"
                  width="100%"
                  alt=""
                  className="sli-img"
                />
              </div>
              <div>
                <img
                  src="/assets/images/ads/fathers-day1.png"
                  width="100%"
                  alt=""
                  className="sli-img"
                />
              </div>
              <div>
                <img
                  src="/assets/images/ads/ad-2.jpeg"
                  width="100%"
                  alt=""
                  className="sli-img"
                />
              </div>

              <div>
                <img
                  src="/assets/images/ads/ad-3.jpeg"
                  width="100%"
                  alt=""
                  className="sli-img"
                />
              </div>
              <div>
                <img
                  src="/assets/images/ads/new3.jpeg"
                  width="100%"
                  alt=""
                  className="sli-img"
                />
              </div>
              <div>
                <img
                  src="/assets/images/ads/fathers-day2.png"
                  width="100%"
                  alt=""
                  className="sli-img"
                />
              </div>
              <div>
                <img
                  src="/assets/images/ads/ad-4.jpeg"
                  width="100%"
                  alt=""
                  className="sli-img"
                />
              </div>
            </Slider>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Advertisement;
