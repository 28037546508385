const CardLoading = () => {
  return (
    <img
      src="/assets/images/loading/card.gif"
      alt="Product"
      width="100%"
      className="pr-2"
    />
  );
};

export default CardLoading;
