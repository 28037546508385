const AccountCreated = ({ makePayment }) => {
  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div style={{ width: "100px", height: "100px" }}>
          <img src="/assets/images/success.png" width="100%" alt="Success" />
        </div>
        <div className="subtitle">
            Success!!!
        </div>
        <div className="text-desc">
            An Account has been created for you.
            The <b>4-digit verification code</b> will be your temporal PIN to login.
            Download Our Mobile App from your App Store to reset pin.
        </div>
        <button className="btn btn-success btn-next mt-2" onClick={makePayment}>
          Click to Initiate Payment
        </button>
      </div>
    </>
  );
};

export default AccountCreated;
