import { useEffect, useState } from "react";
import axios from "axios";
import OtpVerification from "./OtpVerification";
import LoginForm from "./LoginForm";
import AccountCreated from "./AccountCreated";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";

const ShopLoginDialog = ({ makePayment }) => {
  const [phone, setPhone] = useState();
  const [userData, setUserData] = useState({
    telephone: "",
    name: "",
    user_type: "customer",
    otp_code: "",
  });

  useEffect(() => {
    return () => {
      setUserData((userData) => ({
        ...userData,
        telephone: phone,
      }));
    };
  }, [phone]);

  const [isLoading, setIsLoading] = useState(false);
  const [screen, setScreen] = useState("otpRequest");
  // const [screen,setScreen]=useState("account_created");

  const handleUserData = (e) => {
    setUserData((userData) => ({
      ...userData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOtp = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // setScreen('otp');
    axios
      .post("/prime.sika/v1/customers/guest-otp", {
        telephone: phone,
        name: userData?.name,
        user_type: "customer",
        otp_code: userData?.otp_code,
      })
      .then((res) => {
        setScreen("otpVerification");
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  var currentScreen;
  var title;
  if (screen === "otpRequest") {
    title = "Let Us Know You";
    currentScreen = (
      <form onSubmit={handleOtp}>
        <div className="row">
          <div className="col-md-12 text-left mb-2 mt-3">
            <label htmlFor="phone" className="sub-text text-left">
              Phone Number
            </label>
            {/* <input
              type="tel"
              name="telephone"
              required
              className="form-control card-quantity-textbox1"
              placeholder="Enter phone number here"
              value={userData.telephone}
              onChange={handleUserData}
            /> */}
            <PhoneInput
              country="GH"
              defaultCountry="GH"
              // countries={['GH']}
              value={phone}
              onChange={setPhone}
              className="form-control card-quantity-textbox"
              placeholder="phone"
            />
            <div className="text-danger">
              {phone
                ? isValidPhoneNumber(phone)
                  ? ""
                  : "Invalid phone number"
                : "Phone number required"}
            </div>
          </div>
          <div className="col-md-12 text-left mb-2 mt-3">
            <label htmlFor="name" className="sub-text text-left">
              Name (Optional)
            </label>
            <input
              type="text"
              className="form-control text-left card-quantity-textbox1"
              placeholder="Enter your name here"
              name="name"
              value={userData.name}
              onChange={handleUserData}
              id=""
            />
          </div>
          <div className="col-md-12 text-center mt-3">
            {!isLoading ? (
              <button type="submit" className="btn btn-success">
                Proceed To Payment
              </button>
            ) : (
              <button type="button" disabled className="btn btn-success">
                Please Wait..
              </button>
            )}
          </div>
        </div>
      </form>
    );
  } else if (screen === "otpVerification") {
    currentScreen = (
      <OtpVerification
        userData={userData}
        handleUserData={handleUserData}
        setScreen={setScreen}
        makePayment={makePayment}
      />
    );
  } else if (screen === "account_created") {
    title = "";
    currentScreen = (
      <AccountCreated
        // userData={userData}
        // handleUserData={handleUserData}
        // setScreen={setScreen}
        makePayment={makePayment}
      />
    );
  } else if (screen === "login") {
    title = "User Authentication";
    currentScreen = <LoginForm userData={userData} makePayment={makePayment} />;
  }

  return (
    <div className="modal-dialog modal-dialog-width">
      <div className="modal-content">
        <div className="modal-header">
          <div className="row">
            <h2
              className="modal-title modal-title-text-left sub-text text-left"
              id="staticBackdropLabel"
            >
              {title}
            </h2>
          </div>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">{currentScreen}</div>
      </div>
    </div>
  );
};

export default ShopLoginDialog;
