const AboutPrime = () => {
  return (
    <div
      className="card card-back tab-pane fade show active mb-5"
      id="pills-about"
      role="tabpanel"
      aria-labelledby="pills-about-tab"
    >
      <div className="col-lg-12">
        <div className="row">
          <div className="about-text">
            <h4>
              {" "}
              Prime e-Gift is a great way for merchants to offer their valued
              customers an alternative way to send gifts. It empowers
              shoppers(customers) to give and redeem gift cards, helps merchants
              to increase revenue, acquire new consumers, and obtain additional
              brand recognition.
              <span>
                Special times such as CHRISTMAS, NEW YEAR, BIRTHDAY, MOTHERS/
                FATHERS day, VALENTINES day etc., just go on prime to get
                yourself a personalized e-gift card to send.
              </span>
            </h4>
            <h3>FEATURES:</h3>
            <div className="feature-text">
              <h3>
                <span>Prime Wallet</span> – Access all your gift cards in one
                place. Conveniently store all your eGift cards in your prime
                wallet.
              </h3>
              <h3>
                <span>Cash-out</span> – Convert your company branded gift card
                into a prime e-wallet and use it in making purchases of any gift
                card on the platform.
              </h3>
              <h3>
                <span>Re-gift</span> – Share your gift with friends and loved
                ones
              </h3>
              <h3>
                <span>E-Fund</span> – Request your friends and families to help
                fund your gift cards for that special occasion. Invite friends
                to chip in on a gift card, making the perfect group gift for a
                birthday, graduation, mother’s day or any occasion. You can even
                start a group gift for yourself or a friend. Contributors make
                their own payments and add their own personalized message.
              </h3>
              <h3>
                <span>Personal Security Pin</span> - Authenticate all
                transactions with your personal pin
              </h3>
              <h3>
                <span>Acceptable Payments</span> - Momo/Bank card acceptable
                payment and also a direct payment into a designated account most
                used when a full KYC is done first.
              </h3>
              <h3>
                <span>Save Money</span> - As an added bonus, you'll earn Prime
                Points on most purchases. Use your points to earn discounts on
                future purchases or earn free Prime e-gift cards added right to
                your wallet. Get access to our exclusive deals and flash sales.
                Prime points can be converted and cash-out to prime wallet,
                which can also be used to make a purchase of eGift card on
                prime.
              </h3>
              <h3>
                <span>No Hidden fees</span> - Redeem eGift cards for free - no
                fees and no expiration dates. (NB except promotional cards that
                are date bound)
              </h3>
              <h3>
                <span>Send Personalized Prime E-Gift Cards</span> - Give
                personalized eGift cards for any occasion. Choose a recipient
                from your address book, add a message.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutPrime;
