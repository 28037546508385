import Footer from './partials/Footer';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';


const About = () => {
  useEffect(() => {
    let items = document.querySelectorAll(".carousel .carousel-item");

    items.forEach((el) => {
      const minPerSlide = 4;
      let next = el.nextElementSibling;
      for (var i = 1; i < minPerSlide; i++) {
        if (!next) {
          // wrap carousel by using first child
          next = items[0];
        }
        let cloneChild = next.cloneNode(true);
        el.appendChild(cloneChild.children[0]);
        next = next.nextElementSibling;
      }
    });
  },[]);
  return (
    <>
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="row">
            <h1 className="text-center title-text mt-4">
              We are on a mission <br />
              to make <span className="text-green">e-gift</span> card gifting
              fun,
              <br />
              personalized and simple.
            </h1>
          </div>
          <div className="row">
            <img
              src="/assets/images/about-section.png"
              className="no-padding"
              alt=""
              srcset=""
            />
          </div>

          <div className="container-fluid mt-5">
            <div className="row">
              <div className="col-md-12 text-center subtitle3">
                Prime is the first eGift card platform in Ghana, providing the
                platform <br />
                for merchants to offer their valued customers an alternative way
                to send gifts. Empowering shoppers <br />
                to give and redeem gift cards helps stores to increase revenue,
                acquire new consumers,
                <br />
                and obtain additional brand recognition.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-5 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-7">
              <div className="text-center">
                <h2 className="sub-heading">
                  All in one <span className="text-green">solution</span> for
                  Prime
                </h2>

                <p className="sub-text">
                  Take advantage of all the tools we have made available to you
                  as a merhcant and a customer to make your giftinga pleasant
                  one.
                </p>
              </div>
            </div>
          </div>
          <div className="row marg-x">
            <div className="col-md-3">
              <div className="card card-border mb-3">
                <div className="card-body solutions-card-height">
                  <div className="text-center">
                    <img
                      src="/assets/images/rounded-1.png"
                      className="text-center"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div className="text-center subtitle2 mt-3">
                    <b>Prime Merchant</b>
                  </div>
                  
                    <h4 className="text-center text-desc1 mt-2">
                      Our e-gift card solution can be a  powerful way to extend
                      your brand’s reach and increase customer loyalty. That’s why
                      we have dedicated our resources to develop a powerful
                      business tool to expand your omni-channel strategy.
                      <b>
                        The best part of this is FREE to sign up as a merchant.
                      </b>
                    </h4>
                
                  <div className="text-center mt-4">
                  <a href="https://merchant.20pesewas.com/signup" target="_blank" rel="noreferrer" className="btn btn-outline-success suc-btn-pos">Merchant Sign up</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card card-border mb-3">
                <div className="card-body solutions-card-height">
                  <div className="text-center">
                    <img
                      src="/assets/images/rounded-4.png"
                      className="text-center"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div className="text-center subtitle2 mt-3">
                    <b>Prime Plus-Business</b>
                  </div>
                
                    <h4 className="text-center text-desc1 mt-2">
                    We have made it easy for HR managers,  business to reward
                    their staff, managers, and executives with gift cards that 
                    perfectly fits them. &nbsp;
                    <b>
                     Our state of the art print and packaging solution makes it
                      easy to personalize your gift and have it delivered.
                    </b>
                    </h4>
                  
                  <div className="text-center top-margin-4">
                  <Link to="/" className="btn btn-outline-success suc-btn-pos">Buy E-gift card</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card card-border mb-3">
                <div className="card-body solutions-card-height">
                  <div className="text-center">
                    <img
                      src="/assets/images/rounded-3.png"
                      className="text-center"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div className="text-center subtitle2 mt-3">
                    <b>Prime Mobile App</b>
                  </div>
                  
                    <h4 className="text-center text-desc1 mt-2">
                    Prime e-gift app is a great place to buy and send egift card
                    on the go. &nbsp;
                    <b>
                      It is the last minute solution to send  out your gift that
                      will be cherised for a lifetime.
                    </b>
                    </h4>
                 
                  <div className="text-center mt-md-6">
                  <a href="https://play.google.com/store/apps/details?id=com.jackbuild.prime.customer" target="_blank" className="btn btn-outline-success suc-btn-pos">Download App</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card card-border mb-3">
                <div className="card-body solutions-card-height">
                  <div className="text-center">
                    <img
                      src="/assets/images/rounded-2.png"
                      className="text-center"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div className="text-center subtitle2 mt-3">
                    <b>Prime Pay</b>
                  </div>
                  
                    <h4 className="text-center text-desc1 mt-2">
                    Make sure you are prepared for every sale, however and
                    whenever your customers want to pay. Prime Pay provides you
                    with a payment solution to accept e-gift card as an
                     additional payment solution for your business.
                    <b>Get paid instantly. Sign up is FREE.</b>
                    </h4>
                  
                  <div className="mt-5 text-center">
                    <a href="tel:0247117448" className="btn btn-outline-success suc-btn-pos">Call us today</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5">
        <div className="container">
          <div className="row justify-content-center align-items-center padding-row">
            <div className="col">
              <div className="sub-heading text-left">
                Prime
                <span className="text-green">Give Back</span>
                <p className="sub-text text-left">
                  We believe in supporting the community. In our very small way
                  we together with our merchants raise funds to Support local
                  organizations and sell eGift cards at the same time.
                </p>
                <p className="sub-text text-left">
                  Set up a custom fundraising campaign in minutes and watch the
                  progress as you reach your goals.
                  <b>Give back to your community!.</b>
                </p>
              </div>
            </div>
            <div className="col">
              <div className="float-end">
                <img src="/assets/images/Give_Back.png" width="100%" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-5 bg-givers py-5">
        <div className="row">
          <div className="text-center sub-heading mt-4 text-white">
            We are a team of <span className="text-gold">givers</span> <br />
            who understands the act of giving
          </div>
          <p className="sub-text mt-3">
            "The greatest gift you can ever give another person is your own
            happiness"
            <br />
            -Esther Hicks
          </p>
          <p className="text-desc text-center mt-5">
            That is what we help you achieve with the 100+ brands we have
            available on prime.
          </p>
        </div>
      </div>

      {/* <div className="container-fluid mt-3">
        <div className="row mt-3">
          <div className="text-center sub-heading mt-5">
            Meet the<span className="text-green"> Team</span>
          </div>
        </div>

        <div className="container-fluid mt-md-5 mt-2">
          <div className="container">
            <div className="d-flex flex-md-row flex-sm-column justify-content-between">
              <div className="p-2 team-title">Board of Directors</div>
              <div className="p-2 carousel slide">
                <a href="#bod" role="button" data-bs-slide="prev">
                  <i
                    className="fa fa-long-arrow-left fa-1x arrow-x-left arrow-x rounded-circle px-1 py-1 bg-white"
                    aria-hidden="true"
                  ></i>
                </a>
                &nbsp;
                <a href="#bod" role="button" data-bs-slide="next">
                  <i
                    className="fa fa-long-arrow-right fa-1x arrow-x-right rounded-circle px-1 py-1 text-white"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="container-fluid text-center my-3">
              <div className="row mx-auto my-auto justify-content-center">
                <div id="bod" className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-inner" role="listbox">
                    <div className="carousel-item active">
                      <div className="col-md-3 col-4">
                        <div className="card mx-1 mobile-card">
                          <div className="card-header carousel-card-bg">
                            <div className="card-img">
                              <img
                                src="/assets/images/team-1.png"
                                className="img-fluid"
                                alt='team'
                              />
                            </div>

                            <div className="text-left subtitle2 mt-2">
                              Melisa Sheldon
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="col-md-3 col-4">
                        <div className="card  mx-1 mobile-card">
                          <div className="card-header carousel-card-bg">
                            <div className="card-img">
                              <img
                                src="/assets/images/team-2.png"
                                className="img-fluid"
                                alt='team'
                              />
                            </div>

                            <div className="text-left subtitle2 mt-2">
                              Melisa Sheldon
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="col-md-3 col-4">
                        <div className="card mx-1 mobile-card">
                          <div className="card-header carousel-card-bg">
                            <div className="card-img">
                              <img
                                src="/assets/images/team-3.png"
                                className="img-fluid"
                                alt='team'
                              />
                            </div>

                            <div className="text-left subtitle2 mt-2">
                              Melisa Sheldon
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="col-md-3 col-4">
                        <div className="card mx-1 mobile-card">
                          <div className="card-header carousel-card-bg">
                            <div className="card-img">
                              <img
                                src="/assets/images/team-1.png"
                                className="img-fluid"
                                alt='team'
                              />
                            </div>

                            <div className="text-left subtitle2 mt-2">
                              Melisa Sheldon
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="col-md-3 col-4">
                        <div className="card mx-1 mobile-card">
                          <div className="card-header carousel-card-bg">
                            <div className="card-img">
                              <img
                                src="/assets/images/team-2.png"
                                className="img-fluid"
                                alt='team'
                              />
                            </div>

                            <div className="text-left subtitle2 mt-2">
                              Melisa Sheldon
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="col-md-3 col-4">
                        <div className="card mx-1 mobile-card">
                          <div className="card-header carousel-card-bg">
                            <div className="card-img">
                              <img
                                src="/assets/images/team-3.png"
                                className="img-fluid"
                                alt='team'
                              />
                            </div>

                            <div className="text-left subtitle2 mt-2">
                              Melisa Sheldon
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid mt-md-5 mt-2">
          <div className="container">
            <div className="d-flex flex-md-row flex-sm-column justify-content-between">
              <div className="p-2 team-title">Staff</div>
              <div className="p-2 carousel slide">
                <a href="#staff" role="button" data-bs-slide="prev">
                  <i
                    className="fa fa-long-arrow-left fa-1x arrow-x-left arrow-x rounded-circle px-1 py-1 bg-white"
                    aria-hidden="true"
                  ></i>
                </a>
                &nbsp;
                <a href="#staff" role="button" data-bs-slide="next">
                  <i
                    className="fa fa-long-arrow-right fa-1x arrow-x-right rounded-circle px-1 py-1 text-white"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="container-fluid text-center my-3">
              <div className="row mx-auto my-auto justify-content-center">
                <div id="staff" className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-inner" role="listbox">
                    <div className="carousel-item active">
                      <div className="col-md-3 col-4">
                        <div className="card mx-1 mobile-card">
                          <div className="card-header carousel-card-bg">
                            <div className="card-img">
                              <img
                                src="/assets/images/team-1.png"
                                className="img-fluid"
                                alt='team'
                              />
                            </div>

                            <div className="text-left subtitle2 mt-2">
                              Melisa Sheldon
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="col-md-3 col-4">
                        <div className="card mx-1 mobile-card">
                          <div className="card-header carousel-card-bg">
                            <div className="card-img">
                              <img
                                src="/assets/images/team-2.png"
                                className="img-fluid"
                                alt='team'
                              />
                            </div>

                            <div className="text-left subtitle2 mt-2">
                              Melisa Sheldon
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="col-md-3 col-4">
                        <div className="card mx-1 mobile-card">
                          <div className="card-header carousel-card-bg">
                            <div className="card-img">
                              <img
                                src="/assets/images/team-3.png"
                                className="img-fluid"
                                alt='team'
                              />
                            </div>

                            <div className="text-left subtitle2 mt-2">
                              Melisa Sheldon
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="col-md-3 col-4">
                        <div className="card mx-1 mobile-card">
                          <div className="card-header carousel-card-bg">
                            <div className="card-img">
                              <img
                                src="/assets/images/team-1.png"
                                className="img-fluid"
                                alt='team'
                              />
                            </div>

                            <div className="text-left subtitle2 mt-2">
                              Melisa Sheldon
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="col-md-3 col-4">
                        <div className="card mx-1 mobile-card">
                          <div className="card-header carousel-card-bg">
                            <div className="card-img">
                              <img
                                src="/assets/images/team-2.png"
                                className="img-fluid"
                                alt='team'
                              />
                            </div>

                            <div className="text-left subtitle2 mt-2">
                              Melisa Sheldon
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="col-md-3 col-4">
                        <div className="card mx-1 mobile-card">
                          <div className="card-header carousel-card-bg">
                            <div className="card-img">
                              <img
                                src="/assets/images/team-3.png"
                                className="img-fluid"
                                alt='team'
                              />
                            </div>

                            <div className="text-left subtitle2 mt-2">
                              Melisa Sheldon
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid mt-md-5 mt-2">
          <div className="container">
            <div className="d-flex flex-md-row flex-sm-column justify-content-between">
              <div className="p-2 team-title">Affiliates</div>
              <div className="p-2 carousel slide">
                <a href="#recipeCarousel" role="button" data-bs-slide="prev">
                  <i
                    className="fa fa-long-arrow-left fa-1x arrow-x-left arrow-x rounded-circle px-1 py-1 bg-white"
                    aria-hidden="true"
                  ></i>
                </a>
                &nbsp;
                <a href="#recipeCarousel" role="button" data-bs-slide="next">
                  <i
                    className="fa fa-long-arrow-right fa-1x arrow-x-right rounded-circle px-1 py-1 text-white"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="container-fluid text-center my-3">
              <div className="row mx-auto my-auto justify-content-center">
                <div
                  id="recipeCarousel"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner" role="listbox">
                    <div className="carousel-item active">
                      <div className="col-md-3 col-4">
                        <div className="card mx-1 mobile-card">
                          <div className="card-header carousel-card-bg">
                            <div className="card-img">
                              <img
                                src="/assets/images/team-1.png"
                                className="img-fluid"
                                alt='team'
                              />
                            </div>

                            <div className="text-left subtitle2 mt-2">
                              Melisa Sheldon
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="col-md-3 col-4">
                        <div className="card mx-1 mobile-card">
                          <div className="card-header carousel-card-bg">
                            <div className="card-img">
                              <img
                                src="/assets/images/team-2.png"
                                className="img-fluid"
                                alt='team'
                              />
                            </div>

                            <div className="text-left subtitle2 mt-2">
                              Melisa Sheldon
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="col-md-3 col-4">
                        <div className="card mx-1 mobile-card">
                          <div className="card-header carousel-card-bg">
                            <div className="card-img">
                              <img
                                src="/assets/images/team-3.png"
                                className="img-fluid"
                                alt='team'
                              />
                            </div>

                            <div className="text-left subtitle2 mt-2">
                              Melisa Sheldon
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="col-md-3 col-4">
                        <div className="card mx-1 mobile-card">
                          <div className="card-header carousel-card-bg">
                            <div className="card-img">
                              <img
                                src="/assets/images/team-1.png"
                                className="img-fluid"
                                alt='team'
                              />
                            </div>

                            <div className="text-left subtitle2 mt-2">
                              Melisa Sheldon
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="col-md-3 col-4">
                        <div className="card mx-1 mobile-card">
                          <div className="card-header carousel-card-bg">
                            <div className="card-img">
                              <img
                                src="/assets/images/team-2.png"
                                className="img-fluid"
                                alt='team'
                              />
                            </div>

                            <div className="text-left subtitle2 mt-2">
                              Melisa Sheldon
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="col-md-3 col-4">
                        <div className="card mx-1 mobile-card">
                          <div className="card-header carousel-card-bg">
                            <div className="card-img">
                              <img
                                src="/assets/images/team-3.png"
                                className="img-fluid"
                                alt='team'
                              />
                            </div>

                            <div className="text-left subtitle2 mt-2">
                              Melisa Sheldon
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div> */}

        <div className="container-fluid bg-join py-5" style={{ marginTop:"-2%" }}>
          <div className="row">
            <div className="text-center text-white sub-heading mt-4">
              Want to Join the team?
            </div>
            <p className="sub-text mt-3">
              We're hiring right across the globe!
              <br />
              Take a look at our Latest career opportunities.
            </p>
            <p className="text-desc text-center mt-3">
              <a href="/about" className="btn btn-orange">
                Join Now
              </a>
            </p>
          </div>
        </div>

        <Footer/>
    </>
  );
};

export default About;
