import GeneralTabs from "./primeusageComponent/GeneralTabs";
import NewMap from "../NewMap";
import AboutPrime from "./primeusageComponent/AboutPrime";
import GiftCardTypes from "./primeusageComponent/GiftCardTypes";
import BuyGiftCards from "./primeusageComponent/BuyGiftCards";
import RedeemGiftCards from "./primeusageComponent/RedeemGiftCards";
const PrimeUsage = () => {
  return (
    <div className="container">
      <div className="col-lg-12 mt-4">
        <div className="prime-usage">
          <h1>Prime Usage</h1>
        </div>
      </div>
      <div className="mt-3">
        <div className="col-lg-12">
          <GeneralTabs />
        </div>
      </div>
      <div className="mt-3">
        <div className="col-lg-12">
          <div className="tab-content" id="pills-tabContent">
            <AboutPrime />
            <GiftCardTypes />
            <BuyGiftCards />
            <RedeemGiftCards />
            <div
              className="card card-back tab-pane fade mb-5"
              id="pills-merchants"
              role="tabpanel"
              aria-labelledby="pills-merchants-tab"
            >
              <NewMap />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrimeUsage;
