
const Category=(props)=>{
    return(
        <>
        <div className="d-flex flex-column">
            <div className="">
                <img
                    src={props.image}
                    width="100%"
                    className="test"
                    alt="category"
                />
            </div>
              <div className="ms-3 category-text mt-2">{props.title}</div>
        </div>
        </>
    );
}

export default Category;