import Footer from "./partials/Footer";

const Affiliate = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="row">
            <h1 className="text-center title-text">
              <span className="text-center sub-heading">Prime eGift Card</span>
              <br />
            </h1>
            <h1 className="text-center title-text">
              <span className="text-green">Affiliate Program</span>
            </h1>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <p className="subtitle no-mb mt-3">
                  Earn generous commissions for every gift card sold by signing
                  up local businesses. Becoming your own boss is the best gift
                  you can ever give yourself.
                </p>
                <div className="text-center mt-3">
                  <button
                    className="btn btn-success"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    Join Now
                  </button>

                  {/* <!-- Affiliate Forms --> */}
                  <div
                    className="modal fade"
                    id="staticBackdrop"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-width">
                      <div className="modal-content">
                        <div className="modal-header">
                          <div className="row">
                            <h2
                              className="modal-title modal-title-text-left sub-title"
                              id="staticBackdropLabel"
                            >
                              Join our Prime E-Gift Program
                            </h2>
                          </div>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <form action="">
                            <div className="row">
                              <div className="col-md-6 text-left mb-2 mt-3">
                                <label for="name" className="subtitle2">
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control text-left"
                                  name="name"
                                  placeholder="Enter your name here"
                                  id=""
                                />
                              </div>
                              <div className="col-md-6 text-left mb-2 mt-3">
                                <label for="phone" className="subtitle2">
                                  Phone Number
                                </label>
                                <input
                                  type="tel"
                                  name="phone"
                                  className="form-control"
                                  placeholder="Enter phone number here"
                                  id=""
                                />
                              </div>

                              <div className="col-md-6 text-left mb-2 mt-3">
                                <label for="name" className="subtitle2">
                                  Email
                                </label>
                                <input
                                  type="text"
                                  className="form-control text-left"
                                  name="name"
                                  placeholder="Enter your email here"
                                  id=""
                                />
                              </div>
                              <div className="col-md-6 text-left mb-2 mt-3">
                                <label for="location" className="subtitle2">
                                  Location
                                </label>
                                <input
                                  type="text"
                                  name="location"
                                  className="form-control"
                                  placeholder="Enter your location here"
                                  id=""
                                />
                              </div>
                              <div className="col-md-12 text-left mb-2 mt-3">
                                <label
                                  for="location"
                                  className="text-left subtitle2"
                                >
                                  Why do you want to join the Affiliate
                                  Program?:
                                </label>
                                <textarea
                                  name=""
                                  className="form-control"
                                  placeholder="Type your answer here"
                                  id=""
                                  cols="30"
                                  rows="5"
                                ></textarea>
                              </div>
                              <div className="col-md-12 text-center">
                                <button type="submit" className="btn btn-success">
                                  Send Request
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Affiliate Forms --> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <img
              src="/assets/images/affiliate-banner.png"
              className="no-padding"
              alt=""
              srcset=""
              width="100%"
            />
          </div>
        </div>
      </div>

      <div className="container-fluid img-background">
        <div className="container mt-7">
          <div className="row">
            <div className="col-md-12 mt-5">
              <div className="text-center">
                <h2 className="sub-heading">How Prime Affiliate Works</h2>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-3">
              <div className="rectangle">
                <div className="circle">
                  <svg
                    width="98"
                    height="98"
                    viewBox="0 0 98 98"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="49" cy="49" r="49" fill="#FFF4D8" />
                    <path
                      d="M42.3335 32.3333V37.3333"
                      stroke="black"
                      stroke-width="2.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M55.6665 32.3333V37.3333"
                      stroke="black"
                      stroke-width="2.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M64 43.1667V51.7167C62.5167 50.5333 60.6333 49.8333 58.5833 49.8333C56.5333 49.8333 54.6167 50.55 53.1167 51.7666C51.1 53.35 49.8333 55.8333 49.8333 58.5833C49.8333 60.2167 50.3 61.7834 51.1 63.0834C51.7167 64.1 52.5167 64.9833 53.4667 65.6667H42.3333C36.5 65.6667 34 62.3333 34 57.3333V43.1667C34 38.1667 36.5 34.8333 42.3333 34.8333H55.6667C61.5 34.8333 64 38.1667 64 43.1667Z"
                      stroke="black"
                      stroke-width="2.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M40.6665 47.3333H50.6665"
                      stroke="black"
                      stroke-width="2.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M40.6665 55.6667H45.0332"
                      stroke="black"
                      stroke-width="2.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M67.3335 58.5833C67.3335 60.2167 66.8668 61.7833 66.0668 63.0833C65.6001 63.8833 65.0168 64.5833 64.3335 65.15C62.8002 66.5167 60.8002 67.3333 58.5835 67.3333C56.6668 67.3333 54.9001 66.7167 53.4668 65.6667C52.5168 64.9833 51.7168 64.1 51.1002 63.0833C50.3002 61.7833 49.8335 60.2167 49.8335 58.5833C49.8335 55.8333 51.1002 53.3499 53.1168 51.7666C54.6168 50.5499 56.5335 49.8333 58.5835 49.8333C60.6335 49.8333 62.5168 50.5333 64.0002 51.7167C66.0335 53.3167 67.3335 55.8 67.3335 58.5833Z"
                      stroke="black"
                      stroke-width="2.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M58.5832 62.75C58.5832 60.45 60.4498 58.5833 62.7498 58.5833C60.4498 58.5833 58.5832 56.7167 58.5832 54.4167C58.5832 56.7167 56.7165 58.5833 54.4165 58.5833C56.7165 58.5833 58.5832 60.45 58.5832 62.75Z"
                      stroke="black"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="rounded-card-text">
                  <p className="subtitle2">Sign Up</p>
                  <p className="text-center text-desc">
                    Join Prime Affiliate program by signing up, get approved,
                    then you are ready to go out there.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="rectangle">
                <div className="circle">
                  <svg
                    width="98"
                    height="98"
                    viewBox="0 0 98 98"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="49" cy="49" r="49" fill="#E5FFF8" />
                    <path
                      d="M34.0166 47.7V55.1833C34.0166 62.6667 37.0166 65.6667 44.4999 65.6667H53.4833C60.9666 65.6667 63.9666 62.6667 63.9666 55.1833V47.7"
                      stroke="black"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M49 49C52.05 49 54.3 46.5167 54 43.4667L52.9 32.3333H45.1166L44 43.4667C43.7 46.5167 45.95 49 49 49Z"
                      stroke="black"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M59.5173 49C62.884 49 65.3507 46.2667 65.0173 42.9167L64.5507 38.3333C63.9507 34 62.284 32.3333 57.9173 32.3333H52.834L54.0007 44.0167C54.284 46.7667 56.7673 49 59.5173 49Z"
                      stroke="black"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M38.3998 49C41.1498 49 43.6331 46.7667 43.8998 44.0167L44.2665 40.3333L45.0665 32.3333H39.9831C35.6165 32.3333 33.9498 34 33.3498 38.3333L32.8998 42.9167C32.5665 46.2667 35.0331 49 38.3998 49Z"
                      stroke="black"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M49.0002 57.3333C46.2168 57.3333 44.8335 58.7167 44.8335 61.5V65.6667H53.1668V61.5C53.1668 58.7167 51.7835 57.3333 49.0002 57.3333Z"
                      stroke="black"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="rounded-card-text">
                  <p className="subtitle2">Local Merchants</p>
                  <p className="text-center text-desc">
                    Sign Up Local Business to upload their company branded
                    e-gift cards
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="rectangle">
                <div className="circle">
                  <svg
                    width="98"
                    height="98"
                    viewBox="0 0 98 98"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="49" cy="49" r="49" fill="#AAE1F9" />
                    <path
                      d="M59.2333 65.0333C57.7666 65.4667 56.0333 65.6667 53.9999 65.6667H43.9999C41.9666 65.6667 40.2333 65.4667 38.7666 65.0333C39.1333 60.7 43.5833 57.2833 48.9999 57.2833C54.4166 57.2833 58.8666 60.7 59.2333 65.0333Z"
                      stroke="black"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M54.0007 32.3333H44.0007C35.6673 32.3333 32.334 35.6667 32.334 44V54C32.334 60.3 34.234 63.75 38.7673 65.0333C39.134 60.7 43.584 57.2833 49.0007 57.2833C54.4173 57.2833 58.8673 60.7 59.234 65.0333C63.7673 63.75 65.6673 60.3 65.6673 54V44C65.6673 35.6667 62.334 32.3333 54.0007 32.3333ZM49.0007 52.6166C45.7007 52.6166 43.034 49.9334 43.034 46.6334C43.034 43.3334 45.7007 40.6667 49.0007 40.6667C52.3007 40.6667 54.9673 43.3334 54.9673 46.6334C54.9673 49.9334 52.3007 52.6166 49.0007 52.6166Z"
                      stroke="black"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M54.9665 46.6334C54.9665 49.9334 52.2999 52.6166 48.9999 52.6166C45.6999 52.6166 43.0332 49.9334 43.0332 46.6334C43.0332 43.3334 45.6999 40.6667 48.9999 40.6667C52.2999 40.6667 54.9665 43.3334 54.9665 46.6334Z"
                      stroke="black"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="rounded-card-text">
                  <p className="subtitle2">Customer</p>
                  <p className="text-center text-desc">
                    As customers buy E-gift cards of your merchants
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="rectangle">
                <div className="circle">
                  <svg
                    width="98"
                    height="98"
                    viewBox="0 0 98 98"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="49" cy="49" r="49" fill="#D4FFDB" />
                    <path
                      d="M44.0007 32.3333H54.0007C62.334 32.3333 65.6673 35.6667 65.6673 44V54C65.6673 62.3333 62.334 65.6667 54.0007 65.6667H44.0007C35.6673 65.6667 32.334 62.3333 32.334 54V44C32.334 35.6667 35.6673 32.3333 44.0007 32.3333Z"
                      stroke="black"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M43.2832 54.45L54.1832 43.55"
                      stroke="black"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M43.966 46.2833C45.0982 46.2833 46.016 45.3655 46.016 44.2334C46.016 43.1012 45.0982 42.1833 43.966 42.1833C42.8339 42.1833 41.916 43.1012 41.916 44.2334C41.916 45.3655 42.8339 46.2833 43.966 46.2833Z"
                      stroke="black"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M54.8664 55.8167C55.9986 55.8167 56.9164 54.8988 56.9164 53.7666C56.9164 52.6345 55.9986 51.7167 54.8664 51.7167C53.7342 51.7167 52.8164 52.6345 52.8164 53.7666C52.8164 54.8988 53.7342 55.8167 54.8664 55.8167Z"
                      stroke="black"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="rounded-card-text">
                  <p className="subtitle2">Commission</p>
                  <p className="text-center text-desc">
                    You earn a commission. <br />
                    That Simple!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-7 bg-light-1">
        <div className="container bg-light-1">
          <div className="row mt-5">
            <div className="col-md-6">
              <img src="/assets/images/affiliate-man.png" alt="" width="100%" />
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-4 mt-12 px-7">
              <h2 className="sub-heading text-left">
                <span className="text-green">Commissions</span> Are Paid Instantly
              </h2>
              <p className="sub-text text-left">
                Earn up to 15% commission on every gift card sold from the list
                of your merchants for the next 3years. Monitor sales on your
                prime affiliate dashboard
              </p>
            </div>
          </div>
        </div>

        <div className="container-fluid bg-light-2">
          <div className="container mt-7">
            <div className="row">
              <div className="col-md-6 mt-12">
                <h2 className="sub-heading text-left">
                  All the <span className="text-green">tools</span> you need
                </h2>
                <p className="sub-text text-left">
                  Access will be granted you on Affiliate dashboard where you
                  sign up merchants, view sales, reports and cash out
                  commissions. Promote your client's e-cards on social media
                  platforms and many more.
                </p>
              </div>
              <div className="col-md-6 mt-4">
                <img src="/assets/images/all-tools.png" alt="" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-5 bg-join py-5">
        <div className="row">
          <div className="text-center sub-heading mt-4 text-white">Want to Join the team?</div>
          <p className="sub-text mt-3">
            We're hiring right across the globe!
            <br />
            Take a look at our Latest career opportunities.
          </p>
          <p className="text-desc text-center mt-3">
            <button
              type="button"
              className="btn btn-orange"
              data-bs-toggle="modal"
              data-bs-target="#join"
            >
              {" "}
              Join Now
            </button>
          </p>
          {/* <p className="text-desc text-center mt-3">
            <a href=""
              className="btn btn-orange"
            >
              {" "}
              Join Now
            </a>
          </p> */}
          {/* <!-- Join Team Modal --> */}
          <div
            className="modal fade"
            id="join"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-width">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="row">
                    <h2
                      className="modal-title modal-title-text-left sub-title"
                      id="staticBackdropLabel"
                    >
                      Join our Team
                    </h2>
                  </div>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form action="">
                    <div className="row">
                      <div className="col-md-6 text-left mb-2 mt-3">
                        <label for="name" className="subtitle2">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control text-left"
                          name="name"
                          placeholder="Enter your name here"
                          id=""
                        />
                      </div>
                      <div className="col-md-6 text-left mb-2 mt-3">
                        <label for="phone" className="subtitle2">
                          Phone Number
                        </label>
                        <input
                          type="tel"
                          name="phone"
                          className="form-control"
                          placeholder="Enter phone number here"
                          id=""
                        />
                      </div>

                      <div className="col-md-6 text-left mb-2 mt-3">
                        <label for="name" className="subtitle2">
                          Profession
                        </label>
                        <input
                          type="text"
                          className="form-control text-left"
                          name="name"
                          placeholder="Enter your profession here"
                          id=""
                        />
                      </div>
                      <div className="col-md-6 text-left mb-2 mt-3">
                        <label for="location" className="subtitle2">
                          Location
                        </label>
                        <input
                          type="text"
                          name="location"
                          className="form-control"
                          placeholder="Enter your location here"
                          id=""
                        />
                      </div>
                      <div className="col-md-12 text-left mb-2 mt-3">
                        <label for="location" className="text-left subtitle2">
                          Tell us more about you
                        </label>
                        <textarea
                          name=""
                          className="form-control"
                          placeholder="Type your message here"
                          id=""
                          cols="30"
                          rows="5"
                        ></textarea>
                      </div>
                      <div className="col-md-12 text-center">
                        <button type="submit" className="btn btn-success">
                          Send Request
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </>
  );
};

export default Affiliate;
