import { useEffect } from 'react';
import Product from './Product';


const NewArrival = () => {
    useEffect(() => {
        let items = document.querySelectorAll(".carousel .carousel-item");
    
        items.forEach((el) => {
          const minPerSlide = 4;
          let next = el.nextElementSibling;
          for (var i = 1; i < minPerSlide; i++) {
            if (!next) {
              // wrap carousel by using first child
              next = items[0];
            }
            let cloneChild = next.cloneNode(true);
            el.appendChild(cloneChild.children[0]);
            next = next.nextElementSibling;
          }
        });
      },[]);
  return (
    <>
      <div className="container-fluid  mt-2">
        <div className="container">
          <div className="d-flex flex-md-row flex-sm-column justify-content-between ml-2 mr-2">
            <div className="p-2">
              <div className="subtitle text-dark text-left text-left1">
                New Arrival
              </div>
            </div>
            <div className="p-2 carousel slide">
              <a href="#bod" role="button" data-bs-slide="prev">
                <i
                  className="fa fa-long-arrow-left fa-1x arrow-x-left arrow-x rounded-circle px-1 py-1 bg-white"
                  aria-hidden="true"
                ></i>
              </a>
              &nbsp;
              <a href="#bod" role="button" data-bs-slide="next">
                <i
                  className="fa fa-long-arrow-right fa-1x arrow-x-right rounded-circle px-1 py-1 text-white"
                  aria-hidden="true"
                ></i>
              </a>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="container-fluid text-center my-3">
              <div className="row mx-auto my-auto justify-content-center">
                <div id="bod" className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-inner" role="listbox">
                    <div className="carousel-item active">
                      <div className="col-md-3 col-4">
                        <Product image="/assets/images/new-arrival1.png" title="Product Name" location="East Lagon,Accra" price="100"/>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="col-md-3 col-4">
                        <Product image="/assets/images/new-arrival2.png" title="Product Name" location="East Lagon,Accra" price="100"/>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="col-md-3 col-4">
                      <Product image="/assets/images/new-arrival3.png" title="Product Name" location="East Lagon,Accra" price="100"/>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="col-md-3 col-4">
                      <Product image="/assets/images/new-arrival4.png" title="Product Name" location="East Lagon,Accra" price="100"/>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="col-md-3 col-4">
                      <Product image="/assets/images/new-arrival1.png" title="Product Name" location="East Lagon,Accra" price="100"/>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="col-md-3 col-4">
                      <Product image="/assets/images/new-arrival2.png" title="Product Name" location="East Lagon,Accra" price="100"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End of Mobile App Carousel --> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewArrival;
