import BestSelling from "./marketComponents/BestSelling";
import NewArrival from "./marketComponents/NewArrival";
import Product from "./marketComponents/Product";
import SlickSider from "./marketComponents/SlickSlider";
import Footer from "./partials/Footer";
import { useState } from "react";
import Category from './marketComponents/Category';

const Market = () => {
  const [categories, setCategories] = useState([
    {
      id: 1,
      image: "/assets/images/furniture.png",
      title: "Furniture",
    },
    {
      id: 2,
      image: "/assets/images/fast-food.png",
      title: "Fast Food",
    },
    {
      id: 3,
      image: "/assets/images/hotel.png",
      title: "Hotel",
    },
    {
      id: 4,
      image: "/assets/images/beauty.png",
      title: "Beauty",
    },
    {
      id: 5,
      image: "/assets/images/guest-house.png",
      title: "Guest House",
    },
    {
      id: 6,
      image: "/assets/images/movies.png",
      title: "Movies",
    },
    {
      id: 7,
      image: "/assets/images/baby shower.png",
      title: "Baby Shower",
    },
    {
      id: 8,
      image: "/assets/images/Airline.png",
      title: "Airline",
    },
    {
      id: 9,
      image: "/assets/images/Petrol Station.png",
      title: "Petrol Station",
    },
    {
      id: 10,
      image: "/assets/images/fashion.png",
      title: "Fashion",
    },
    {
      id: 11,
      image: "/assets/images/application.png",
      title: "Appreciation",
    },
  ]);
  const [categoryCount, setCategoryCount] = useState(0);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="subtitle2 text-left ml-text mt-3">
            Browse by category
          </div>
        </div>
        <div className="row mt-3 ml-2 mr-2">
          <div className="col category">
            <a
              href="#collapseExample"
              data-bs-toggle="collapse"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <Category image="/assets/images/category.png" title="Show All" />
            </a>
          </div>
          {categories.map((category) => {
            if (categoryCount === 8 || categoryCount === 9) {
              return (
                <div className="col category d-none d-lg-block" key={category.id}>
                  <Category
                    image={category.image}
                    title={category.title}
                    id={category.id}
                    />
                </div>
              )
            } else {
              return (
                <div className="col-md-1 col-lg-1 col category" key={category.id}>
                  <Category
                    image={category.image}
                    title={category.title}
                    id={category.id}
                    />
                </div>
              );
            }
          })}
          {/* <div className="col category">
            <Category image="/assets/images/fast-food.png" title="Fast Food" />
          </div>
          <div className="col category">
            <Category image="/assets/images/hotel.png" title="Hotel" />
          </div>
          <div className="col category">
            <Category image="/assets/images/beauty.png" title="Beauty" />
          </div>
          <div className="col category">
            <Category
              image="/assets/images/guest-house.png"
              title="Guest House"
            />
          </div>
          <div className="col category">
            <Category image="/assets/images/movies.png" title="Movies" />
          </div>
          <div className="col category">
            <Category
              image="/assets/images/baby shower.png"
              title="Baby Shower"
            />
          </div>
          <div className="col category">
            <Category image="/assets/images/Airline.png" title="Airline" />
          </div>
          <div className="col category">
            <Category
              image="/assets/images/Petrol Station.png"
              title="Petrol Station"
            />
          </div>
          <div className="col category d-none d-lg-block">
            <Category image="/assets/images/fashion.png" title="Fashion" />
          </div>
          <div className="col category d-none d-lg-block">
            <Category
              image="/assets/images/application.png"
              title="Appreciation"
            />
          </div> */}
        </div>
        {/* <!-- Other Icon Groups --> */}
        <div className="collapse" id="collapseExample">
          <div className="row mt-3 ml-2 mr-2">
            <div className="col category">
              <Category
                image="/assets/images/father's_day.png"
                title="Father's Day"
              />
            </div>
            <div className="col category">
              <Category image="/assets/images/easter.png" title="Easter" />
            </div>
            <div className="col category">
              <Category image="/assets/images/wedding.png" title="Wedding" />
            </div>
            <div className="col category">
              <Category
                image="/assets/images/romantic.png"
                title="Romantic Dinner"
              />
            </div>
            <div className="col category">
              <Category image="/assets/images/groceries.png" title="Groceries" />
            </div>
            <div className="col category">
              <Category image="/assets/images/barber.png" title="Barber" />
            </div>
            <div className="col category">
              <Category image="/assets/images/ticket.png" title="Event Ticket" />
            </div>
            <div className="col category">
              <Category
                image="/assets/images/graduation.png"
                title="Graduation"
              />
            </div>
            <div className="col category">
              <Category
                image="/assets/images/restaurant.png"
                title="Restaurant"
              />
            </div>
            <div className="col category">
              <Category image="/assets/images/christmas.png" title="Christmas" />
            </div>
            <div className="col category d-none d-lg-block">
              <Category
                image="/assets/images/Mother's Day.png"
                title="Mother's Day"
              />
            </div>
            <div className="col category d-none d-lg-block">
              <Category image="/assets/images/brand.png" title="Brand" />
            </div>
          </div>
        </div>

        <div className="row mr-2 mt-3">
          <form>
            <div className="input-group">
              <div className="input-group-append">
                <button className="ms-4 btn btn-outline-secondary bg-green text-white border-start-0 border rounded-pill ms-n3 btn-left py-2">
                  <i className="fa fa-search"></i>
                </button>
              </div>
              <input
                type="text"
                placeholder="Search here..."
                className="form-control  rounded-pill search-left py-3"
              />
            </div>
          </form>
        </div>
      </div>

      <SlickSider />

      {/* New Arrival */}
      <NewArrival />

      <BestSelling />

      <SlickSider />

      <div className="container">
        <div className="row">
          <div className="col-md-3 mt-2">
            <Product
              image="/assets/images/best-selling1.png"
              title="Product Name"
              location="East Lagon,Accra"
              price="100"
            />
          </div>
          <div className="col-md-3 mt-2">
            <Product
              image="/assets/images/best-selling2.png"
              title="Product Name"
              location="East Lagon,Accra"
              price="100"
            />
          </div>
          <div className="col-md-3 mt-2">
            <Product
              image="/assets/images/best-selling3.png"
              title="Product Name"
              location="East Lagon,Accra"
              price="100"
            />
          </div>
          <div className="col-md-3 mt-2">
            <Product
              image="/assets/images/best-selling4.png"
              title="Product Name"
              location="East Lagon,Accra"
              price="100"
            />
          </div>

          <div className="col-md-3 mt-2">
            <Product
              image="/assets/images/best-selling1.png"
              title="Product Name"
              location="East Lagon,Accra"
              price="100"
            />
          </div>
          <div className="col-md-3 mt-2">
            <Product
              image="/assets/images/best-selling2.png"
              title="Product Name"
              location="East Lagon,Accra"
              price="100"
            />
          </div>
          <div className="col-md-3 mt-2">
            <Product
              image="/assets/images/best-selling3.png"
              title="Product Name"
              location="East Lagon,Accra"
              price="100"
            />
          </div>
          <div className="col-md-3 mt-2">
            <Product
              image="/assets/images/best-selling4.png"
              title="Product Name"
              location="East Lagon,Accra"
              price="100"
            />
          </div>
        </div>
      </div>

      <SlickSider />
      <Footer />
    </>
  );
};

export default Market;
