import axios from "axios";
import React, { useState } from "react";
import { ClipLoader } from "react-spinners";
import { toast } from "sonner";
import Swal from "sweetalert2";

function DeleteAccount({ phoneNumber, otpDigit, setCurrentScreen }) {
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const result = await Swal.fire({
        title: "Are you sure you want to delete your account?",
        text: "This action cannot be reversed",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#febf4c",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, I'm sure",
        cancelButtonText: "No, cancel",
      });

      if (result.isConfirmed) {
        setLoading(true);
        const res = await axios.delete(
          `/prime.sika/v1/customers/account-profile/remove?telephone=${phoneNumber}&otp=${otpDigit}`
        );
        setLoading(false);

        const msg = res.data.data.message;
        toast.success(msg, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setCurrentScreen(1);
      }
    } catch (error) {
      console.error(error.response?.data?.error_msg || error.message);
      setLoading(false);
      toast.error(error.response?.data?.error_msg || "An error occurred", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="card card-shadow1">
        <div className="form-group d-flex">
          <input
            className="form-control numb-textbox"
            type="number"
            name="telephone"
            readOnly
            value={phoneNumber}
          />
        </div>
        <div className="row mt-4">
          <div className="pad-sm">
            <button type="submit" className="btn btn-danger w-100" disabled={isLoading}>
              {isLoading ? (
                <div className="d-flex align-items-center justify-content-center gap-2">
                  <ClipLoader
                    color={"#fff"}
                    loading={true}
                    size={18}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  <h4 className="cont-font">Deleting</h4>
                </div>
              ) : (
                <h4 className="cont-font">Delete My Account</h4>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default DeleteAccount;
