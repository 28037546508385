import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const SlickSider = () => {
  var settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
  };
  return (
    <>
      <div className="container-fluid mt-3 d-none d-sm-none d-md-block d-lg-block">
        <div className="ml-2">
          <div className="row">
            <div className="col-md-11 ml-2 mr-2">
              <Slider {...settings}>
                <div>
                  <img
                    src="/assets/images/Shop-Image1.png"
                    width="100%"
                    alt=""
                    srcset=""
                    className="pr-2"
                  />
                </div>

                <div>
                  <img
                    src="/assets/images/Shop-Image2.png"
                    width="100%"
                    alt=""
                    srcset=""
                    className="pr-2"
                  />
                </div>

                <div>
                  <img
                    src="/assets/images/Shop-Image2.png"
                    width="100%"
                    alt=""
                    srcset=""
                    className=" pr-2"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SlickSider;
