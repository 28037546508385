import CardValue from "./CardValue";
import CardSlider from "./CardSlider";
import TextLoading from "./../../../partials/TextLoading";
import axios from "axios";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
// import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";

const CardDetails = ({
  cards,
  handleSelected,
  userInput,
  handleUserInput,
  appendAmount,
  popAmount,
  amountArr,
  isAmountSelected,
  total,
  setScreen,
  cart,
  phone,
  setPhone
}) => {
  var card_amounts = cards.sort((a,b)=>a.amount-b.amount).map((item, index) => {
    return (
      <CardValue
        id={item.id}
        amount={item.amount}
        key={index}
        handleSelected={handleSelected}
        appendAmount={appendAmount}
        popAmount={popAmount}
      />
    );
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(phone);
    var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    if (!isAmountSelected) {
      alert("Please select at least one card");
    } else if (phone===undefined||isValidPhoneNumber(phone)===false) {
      alert("Please Enter A Valid phone number");
    } else {
      axios
        .post("/prime.sika/v1/carts/customer/create", { items: cart })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
      setScreen("preview");
    }
  };

  const capitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-5">
          <div className="row">
            <CardSlider
              card={cards[0]}
              amountArr={amountArr}
              isAmountSelected={isAmountSelected}
            />

            <div className="row mt-3">
              <div className="subtitle2 text-left text-black">
                <b>Merchant Info</b>
              </div>
              <div className="mt-2">
                <p className="sub-text text-left">{cards[0]?.description}</p>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-5">
                <div className="subtitle2 text-left text-black">Location</div>
                <p className="sub-text text-left">
                  {cards[0]?.client?.location}
                </p>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-6">
                <div className="subtitle2 text-left text-black">
                  Contact Info
                </div>
                <p className="sub-text text-left">
                  {cards[0]?.client_telephone}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-5 mt-2">
          <div className="container">
            <div className="d-flex flex-row justify-content-between">
              <div className="p-0">
                <h3 className="shop-sub-text text-left">
                  {capitalize(cards[0]?.title) ? (
                    capitalize(cards[0]?.client_name)
                  ) : (
                    <TextLoading />
                  )}
                </h3>
              </div>
              <div className="total-container">
                <div className="shop-card-text-desc">
                  Total:{" "}
                  <span className="total-amount">¢{total.toFixed(2)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <div className="row">
              <div className="col">
                <div className="sub-text text-left">
                  <b>Select card value in Ghana Cedis</b>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row mt-3">
              {/* <div className="sub-text text-left"> */}
              {/* <div className="row"> */}
              {card_amounts}
              {/* </div> */}
              {/* </div> */}
              {/* <small className="mt-3">
                  Amount Selected GH¢ {amountArr.map(value=>`${value}, `)}
                </small> */}
            </div>
          </div>

          <div className="row mt-3">
            <form onSubmit={handleSubmit}>
              <div className="sub-text text-left mt-3">
                <div className="sub-text text-left mb-4">
                  <b className="mb-3">Recipient number</b>
                  <p className="text-left text-faint">
                    Enter the number of the receipient
                  </p>
                  <div className="form-group mt-3">
                    {/* <input
                      type="tel"
                      className="form-control "
                      name="phone"
                      value={userInput.phone}
                      onChange={handleUserInput}
                      required
                    /> */}
                     <PhoneInput
                    country="GH"
                    defaultCountry="GH"
                    // countries={['GH']}
                    value={phone}
                    onChange={setPhone}
                    className='form-control card-quantity-textbox'
                    placeholder="phone"
                    error={phone ? (isValidPhoneNumber(phone) ? '' : 'Invalid phone number') : 'Phone number required'}
                  />
                  {/* <div className="text-danger">{phone && }</div> */}
                  </div>
                </div>
              </div>
              
              <div className="row">
                <div className="col-md-12 mt-0">
                  <div className="container">
                    <div className="sub-text text-left mt-0">
                      <b className="">Add Message</b>
                      <p className="text-faint text-left">
                        Add a personal message or greetings
                      </p>
                    </div>

                    <div className="mt-3">
                      <div className="row">
                        <textarea
                          name="message"
                          cols="30"
                          rows="5"
                          className="text-area"
                          value={userInput.message}
                          onChange={handleUserInput}
                        ></textarea>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3 mt-3">
                        <button
                          className="btn btn-success px-5 py-3"
                          type="submit"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default CardDetails;
