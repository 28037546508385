import GiftCard from "./GiftCard";
import { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";

const NewCards = () => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    axios
      .get("/prime.sika/v1/published-cards/list", {
        params: { is_new: true },
      })
      .then((res) => {
        setCards(res.data.data.cards);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  const settings = {
    infinite: true,
    autoplay: true,
    variableWidth: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          // slidesToShow: 3,
          // slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          // slidesToShow: 2,
          // slidesToScroll: 2,
          variableWidth: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: false,
          dots: false,
        },
      },
   
    ],
  };

  if (cards.length > 0) {
    return (
      <>
        <div className="row">
          <div className="shop-text-header mt-4">
            <h2>New on Prime</h2>
          </div>
        </div>

        <div className="row">
          <Slider {...settings}>
            {cards.map((item, index) => (
              <div className="col-md-2 col-lg-2 col-sm-6 mt-2" key={index}>
                <GiftCard
                  id={item.client_id}
                  image={item.image}
                  title={item.client_name}
                  client_location={item.client_location}
                  amount={`${item.client.min_card_price} - ${item.client.max_card_price}`}
                />
              </div>
            ))}
          </Slider>
        </div>
      </>
    );
  }
};

export default NewCards;
