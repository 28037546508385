import Footer from "../guest/partials/Footer";
import { useNavigate } from "react-router-dom";

const Failed = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center m-4">
        <img
          src="/assets/images/failed.jpg"
          width="50%"
          className="img-fluid"
          alt="failed"
        />
        <h5 className="text-danger diplay-5" style={{ marginBottom: "50px" }}>
          Payment not Successful
        </h5>
        <button
          className="btn btn-danger btn-block"
          id="closeBtn"
          onClick={() => navigate("/")}
        >
          Close
        </button>
      </div>
      <Footer />
    </>
  );
};

export default Failed;
