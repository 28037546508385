const GeneralTabs = () => {
  return (
    <div>
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link btn bg-dark-light tab-btn1 px-lg-4 px-sm-5 mr-2 active"
            id="pills-about-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-about"
            type="button"
            role="tab"
            aria-controls="pills-about"
            aria-selected="true"
          >
            About Prime E-Gift<div className="str-line1"></div>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link btn bg-dark-light tab-btn1 px-lg-4 px-sm-5 mr-2"
            id="pills-types-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-types"
            type="button"
            role="tab"
            aria-controls="pills-types"
            aria-selected="false"
          >
            Types of Gift Cards on Prime<div className="str-line4"></div>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link btn bg-dark-light tab-btn1 px-lg-4 px-sm-5 mr-2"
            id="pills-buy-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-buy"
            type="button"
            role="tab"
            aria-controls="pills-buy"
            aria-selected="false"
          >
            How to buy a Gift Card on Prime<div className="str-line3"></div>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link btn bg-dark-light tab-btn1 px-lg-4 px-sm-5 mr-2"
            id="pills-redeem-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-redeem"
            type="button"
            role="tab"
            aria-controls="pills-redeem"
            aria-selected="false"
          >
            How to Redeem your Gift Card/ Promo code
            <div className="str-line2"></div>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link btn bg-dark-light tab-btn1 px-lg-4 px-sm-5 mr-2"
            id="pills-merchants-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-merchants"
            type="button"
            role="tab"
            aria-controls="pills-merchants"
            aria-selected="false"
          >
            Merchants we have on Prime<div className="str-line5"></div>
          </button>
        </li>
      </ul>
      <hr className="hr2 mx-2" />
    </div>
  );
};
export default GeneralTabs;
