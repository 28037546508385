import { useState, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  InfoWindow,
} from "@react-google-maps/api";
import axios from "axios";
import PagePagination from "./others/primeusageComponent/PagePagination";
import LoadingPage from "./others/primeusageComponent/LoadingPage";

export default function NewMap() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBsnVLVZOQYt7P1IJKKZ_nAoBjlMn5wgjw",
    libraries: ["places"],
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map />;
}

function Map() {
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [merchants, setMerchants] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isloaded, setIsLoaded] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (search === "") {
      axios
        .get(`/market.place/v1/merchants/list?limit=15&page=${currentPage}`)
        .then((res) => {
          setIsLoaded(true);
          setMerchants(res.data.data.merchants);
          setTotalPage(res.data.data.total_pages);
        })
        .catch((err) => {
          setIsLoaded(false);
          console.log(err.response);
        });
    } else {
      axios
        .get(`/market.place/v1/merchants/list?limit=50&search_string=${search}`)
        .then((res) => {
          setIsLoaded(true);
          setMerchants(res.data.data.merchants);
          setTotalPage(res.data.data.total_pages);
        })
        .catch((err) => {
          setIsLoaded(true);
          console.log(err.response);
        });
    }
  }, [currentPage, search]);

  const capitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  return (
    <>
      <GoogleMap
        zoom={12}
        center={{ lat: 5.6037, lng: -0.187 }}
        mapContainerStyle={{ width: "100%", height: "70vh" }}
      >
        {merchants.map((currentPost) => (
          <MarkerF
            key={currentPost.code}
            position={{
              lat: Number(currentPost.cordinates?.latitude),
              lng: Number(currentPost.cordinates?.longitude),
            }}
            onClick={() => {
              setSelectedMerchant(currentPost);
            }}
          />
        ))}
        {selectedMerchant && (
          <InfoWindow
            position={{
              lat: Number(selectedMerchant.cordinates?.latitude),
              lng: Number(selectedMerchant.cordinates?.longitude),
            }}
            onCloseClick={() => {
              setSelectedMerchant(null);
            }}
          >
            <div className="address-size">
              <div className="d-flex justify-content-between">
                <div className="logo_cont">
                  <img src={selectedMerchant?.logo_url} alt="" />
                </div>
                <div className="my-auto">
                  <button
                    onClick={() =>
                      window.open(
                        `https://maps.google.com/maps?q=${Number(
                          selectedMerchant.cordinates?.latitude
                        )},${Number(selectedMerchant.cordinates?.longitude)}`
                      )
                    }
                    target={"_blank"}
                    className="btn btn-outline-success btn-h"
                  >
                    <h2>View Location</h2>
                  </button>
                </div>
              </div>
              <div className="mt-2">
                <h4 title={capitalize(selectedMerchant.name)}>
                  Company Name:{" "}
                  <span>
                    {selectedMerchant.name
                      ? capitalize(selectedMerchant.name)
                      : "N/A"}
                  </span>
                </h4>
                <h4 title={capitalize(selectedMerchant.cordinates?.address)}>
                  Location:{" "}
                  <span>
                    {selectedMerchant.cordinates?.address
                      ? capitalize(selectedMerchant.cordinates?.address)
                      : "N/A"}
                  </span>
                </h4>
                <h4>
                  Tel:{" "}
                  <span>
                    {selectedMerchant.telephone
                      ? selectedMerchant.telephone
                      : "N/A"}
                  </span>
                </h4>
                <h4 title={selectedMerchant.description}>
                  Description:{" "}
                  <span>
                    {selectedMerchant.description
                      ? selectedMerchant.description
                      : "N/A"}
                  </span>
                </h4>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
      <div className="card card-chart z-position card-height-2 mt-5">
        <div className="card-header card-header-margin">
          <div className="col-lg-12">
            <div className="row justify-content-between">
              <div className="col-12 col-md-6">
                <h3 className="my-auto">Merchants On Prime</h3>
              </div>
              <div className="col-12 col-md-6">
                <div className="w-md-50 mb-2">
                  <input
                    type="text"
                    className="form-control text-center"
                    placeholder="Search Merchant"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body booking_card">
          <div class="table-responsive">
            <table class="datatable table table-stripped table table-hover table-center mb-0">
              <thead>
                <tr>
                  <td>Company Name</td>
                  <td>Location</td>
                  <td>Telephone</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {isloaded ? (
                  <>
                    {merchants.length === 0 ? (
                      <div className="no-data-div">
                        <div className="no-data">
                          <h4>No Merchant Found</h4>
                        </div>
                      </div>
                    ) : (
                      merchants
                        ?.filter((item) =>
                          item?.name
                            ?.toLowerCase()
                            .includes(search.toLowerCase())
                        )
                        ?.map((ele, i) => {
                          return (
                            <tr key={i}>
                              <td>{capitalize(ele.name)}</td>
                              <td>
                                {ele.cordinates?.address
                                  ? capitalize(ele.cordinates?.address)
                                  : "N/A"}
                              </td>
                              <td>{ele.telephone}</td>
                              <td>
                                <button
                                  onClick={() =>
                                    window.open(
                                      `https://maps.google.com/maps?q=${Number(
                                        ele?.cordinates?.latitude
                                      )},${Number(ele?.cordinates?.longitude)}`
                                    )
                                  }
                                  target={"_blank"}
                                  className="btn btn-success"
                                >
                                  View Location
                                </button>
                              </td>
                            </tr>
                          );
                        })
                    )}
                  </>
                ) : (
                  <div className="no-data-div">
                    <div className="no-data">
                      <i className="fas fa-spinner fa-spin mr-2"></i>
                    </div>
                  </div>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <PagePagination
        setTotalPage={setTotalPage}
        totalPage={totalPage}
        setCurrentPage={(e) => setCurrentPage(e)}
      />
    </>
  );
}
