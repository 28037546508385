import CustomerContactForm from "./CustomerContactForm";
import Footer from "./partials/Footer";

const CustomerCare = () => {
  return (
    <>
      <div className="container mt-3 bg-customer">
        <div className="row">
          <div className="customer-text-margin-top">
            <div className="text-center sub-heading text-white">Customer Care</div>
            <p className="sub-text mt-2 text-white">
              We're always here to help you!
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid cutomer-negative-mt">
        <div className="container">
          <div className="row d-none d-sm-none d-md-block">
            <div className="d-flex flex-row justify-content-center">
              <div className="p-2">
                <div className="rectangle">
                  <div className="circle2">
                    <svg
                      width="90"
                      height="90"
                      viewBox="0 0 78 78"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="39" cy="39" r="39" fill="#FFF4D8" />
                      <path
                        d="M27.2266 32.9199L38.9999 39.7332L50.6932 32.9599"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M39 51.8132V39.7198"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M51.8122 40.1067V35.2266C51.8122 33.3866 50.4922 31.1467 48.8789 30.2533L41.7589 26.3066C40.2389 25.4533 37.7589 25.4533 36.2389 26.3066L29.1189 30.2533C27.5055 31.1467 26.1855 33.3866 26.1855 35.2266V42.7733C26.1855 44.6133 27.5055 46.8533 29.1189 47.7466L36.2389 51.6933C36.9989 52.12 37.9989 52.3333 38.9989 52.3333C39.9989 52.3333 40.9989 52.12 41.7589 51.6933"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M48.6007 51.5334C50.9571 51.5334 52.8673 49.6231 52.8673 47.2667C52.8673 44.9103 50.9571 43 48.6007 43C46.2443 43 44.334 44.9103 44.334 47.2667C44.334 49.6231 46.2443 51.5334 48.6007 51.5334Z"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M53.6673 52.3333L52.334 51"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="rounded-card-text">
                    <p className="subtitle2 line-height-22 text-left">
                      Track or resend <br /> your gift card
                    </p>
                    <p className="text-left text-desc line-height-22">
                      Check the status of your eGift Cards or resend with our
                      real-time gift tracker.
                    </p>
                    <p className="mb-3 text-left">
                      <a href="/customer" className="customer-care-a">
                        Track my e-gift card{" "}
                        <span className="fa fa-arrow-right"></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-2">
                <div className="rectangle">
                  <div className="circle2">
                    <svg
                      width="90"
                      height="90"
                      viewBox="0 0 78 78"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="39" cy="39" r="39" fill="#ACEAC9" />
                      <path
                        d="M41.666 29.6666H49.666"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M41.666 33.6666H45.666"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M50.9993 38.3333C50.9993 45.3333 45.3327 51 38.3327 51C31.3327 51 25.666 45.3333 25.666 38.3333C25.666 31.3333 31.3327 25.6666 38.3327 25.6666"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M52.3327 52.3333L49.666 49.6666"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="rounded-card-text">
                    <p className="subtitle2 line-height-22 text-left">
                      Search our help <br /> centre
                    </p>
                    <p className="text-left text-desc line-height-22">
                      Answers to FAQ can be found at our Help Centre.
                    </p>
                    <p className="text-left mt-4">
                      <a href="/customer" className="customer-care-a">
                        Search for help <span className="fa fa-arrow-right"></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-2">
                <div className="rectangle">
                  <div className="circle2">
                    <svg
                      width="90"
                      height="90"
                      viewBox="0 0 78 78"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="39" cy="39" r="39" fill="#AAE1F9" />
                      <path
                        d="M45.666 35C45.666 40.16 41.186 44.3333 35.666 44.3333L34.426 45.8266L33.6927 46.7067C33.066 47.4533 31.866 47.2933 31.4527 46.3999L29.666 42.4666C27.2393 40.7599 25.666 38.0533 25.666 35C25.666 29.84 30.146 25.6666 35.666 25.6666C39.6927 25.6666 43.1727 27.8933 44.7327 31.0933C45.3327 32.28 45.666 33.6 45.666 35Z"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M52.3324 40.1467C52.3324 43.2 50.7591 45.9067 48.3324 47.6134L46.5458 51.5467C46.1324 52.44 44.9324 52.6134 44.3058 51.8534L42.3324 49.48C39.1058 49.48 36.2258 48.0534 34.4258 45.8267L35.6658 44.3334C41.1858 44.3334 45.6658 40.16 45.6658 35C45.6658 33.6 45.3324 32.2801 44.7324 31.0934C49.0924 32.0934 52.3324 35.7734 52.3324 40.1467Z"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M32.334 35H39.0007"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="rounded-card-text">
                    <p className="subtitle2 line-height-22 text-left">
                      Chat with us
                    </p>
                    <p className="text-left text-desc line-height-22">
                      Need support or have a question? We're here to help.
                    </p>
                    <p className=" text-left mt-5">
                      <a href="/customer" className="customer-care-a">
                        Start a chat <span className="fa fa-arrow-right"></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="d-block d-sm-block d-md-none">
              <div className="col-md-4">
                <div className="rectangle">
                  <div className="circle2">
                    <svg
                      width="98"
                      height="98"
                      viewBox="0 0 78 78"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="39" cy="39" r="39" fill="#FFF4D8" />
                      <path
                        d="M27.2266 32.9199L38.9999 39.7332L50.6932 32.9599"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M39 51.8132V39.7198"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M51.8122 40.1067V35.2266C51.8122 33.3866 50.4922 31.1467 48.8789 30.2533L41.7589 26.3066C40.2389 25.4533 37.7589 25.4533 36.2389 26.3066L29.1189 30.2533C27.5055 31.1467 26.1855 33.3866 26.1855 35.2266V42.7733C26.1855 44.6133 27.5055 46.8533 29.1189 47.7466L36.2389 51.6933C36.9989 52.12 37.9989 52.3333 38.9989 52.3333C39.9989 52.3333 40.9989 52.12 41.7589 51.6933"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M48.6007 51.5334C50.9571 51.5334 52.8673 49.6231 52.8673 47.2667C52.8673 44.9103 50.9571 43 48.6007 43C46.2443 43 44.334 44.9103 44.334 47.2667C44.334 49.6231 46.2443 51.5334 48.6007 51.5334Z"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M53.6673 52.3333L52.334 51"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="rounded-card-text">
                    <p className="subtitle2 line-height-22 text-left">
                      Track or resend <br /> your gift card
                    </p>
                    <p className="text-left text-desc line-height-22">
                      Check the status of your eGift Cards or resend with our
                      real-time gift tracker.
                    </p>
                    <p className="mb-3 text-left">
                      <a href="/customer" className="customer-care-a">
                        Track my e-gift card{" "}
                        <span className="fa fa-arrow-right"></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="rectangle">
                  <div className="circle2">
                    <svg
                      width="98"
                      height="98"
                      viewBox="0 0 78 78"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="39" cy="39" r="39" fill="#ACEAC9" />
                      <path
                        d="M41.666 29.6666H49.666"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M41.666 33.6666H45.666"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M50.9993 38.3333C50.9993 45.3333 45.3327 51 38.3327 51C31.3327 51 25.666 45.3333 25.666 38.3333C25.666 31.3333 31.3327 25.6666 38.3327 25.6666"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M52.3327 52.3333L49.666 49.6666"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="rounded-card-text">
                    <p className="subtitle2 line-height-22 text-left">
                      Search our help <br /> centre
                    </p>
                    <p className="text-left text-desc line-height-22">
                      Answers to FAQ can be found at our Help Centre.
                    </p>
                    <p className="mb-3 text-left">
                      <a href="/customer" className="customer-care-a">
                        Search for help <span className="fa fa-arrow-right"></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="rectangle">
                  <div className="circle2">
                    <svg
                      width="98"
                      height="98"
                      viewBox="0 0 78 78"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="39" cy="39" r="39" fill="#AAE1F9" />
                      <path
                        d="M45.666 35C45.666 40.16 41.186 44.3333 35.666 44.3333L34.426 45.8266L33.6927 46.7067C33.066 47.4533 31.866 47.2933 31.4527 46.3999L29.666 42.4666C27.2393 40.7599 25.666 38.0533 25.666 35C25.666 29.84 30.146 25.6666 35.666 25.6666C39.6927 25.6666 43.1727 27.8933 44.7327 31.0933C45.3327 32.28 45.666 33.6 45.666 35Z"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M52.3324 40.1467C52.3324 43.2 50.7591 45.9067 48.3324 47.6134L46.5458 51.5467C46.1324 52.44 44.9324 52.6134 44.3058 51.8534L42.3324 49.48C39.1058 49.48 36.2258 48.0534 34.4258 45.8267L35.6658 44.3334C41.1858 44.3334 45.6658 40.16 45.6658 35C45.6658 33.6 45.3324 32.2801 44.7324 31.0934C49.0924 32.0934 52.3324 35.7734 52.3324 40.1467Z"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M32.334 35H39.0007"
                        stroke="black"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="rounded-card-text">
                    <p className="subtitle2 line-height-22 text-left">
                      Chat with us
                    </p>
                    <p className="text-left text-desc line-height-22">
                      Need support or have a question? We're here to help.
                    </p>
                    <p className="mb-3 text-left">
                      <a href="/customer" className="customer-care-a">
                        Start a Chat <span className="fa fa-arrow-right"></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="card p-5 card-radius">
          <div className="row">
            <div className="col-md-6 line-right mb-4">
              <h3>Write to us</h3>
              <p>
                Send us a message and we'll get back to you as soon as possible.
              </p>

              <div className="mt-3">
                <CustomerContactForm/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="col-md-12">
                <img src="/assets/images/customer.png" width="90%" alt="Customer"/>
              </div>
              <div className="col-md-12 mt-3">
                <h4>Can't find what you're after?</h4>
                <p className="sub-text text-left">
                  Our customer team is available Monday-Sunday to help solve
                  your issues. We try our hardest to respond to all queries as
                  soon as possible, however, in some cases we may need some
                  extra time to get the exact information you need.
                </p>
                <div className="row">
                  <div className="col text-green d-flex">
                    <span className="fa fa-envelope mt-1"></span>&nbsp;info@primeegiftcard.com
                  </div>
                  {/* <div className="col text-green d-flex">
                    <span className="fa fa-envelope mt-1"></span>&nbsp;support@primeegiftcard.com
                  </div> */}
                  <div className="col text-green mt-2">
                    <span className="fa fa-phone"></span> +233548958517
                  </div>
                  <div className="col text-green mt-2">
                    <span className="fa fa-phone"></span> +233207920300
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      
    </>
  );
};

export default CustomerCare;
