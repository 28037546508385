import { useEffect } from "react";
import Footer from "./partials/Footer";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Subscribe from "./partials/Subscribe";

const Index = () => {
  var settings = {
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: false,
    centerPadding: "100px",
    variableWidth: false,
    adaptiveHeight: false,
  };
  useEffect(() => {
    let items = document.querySelectorAll(".carousel .carousel-item");

    items.forEach((el) => {
      const minPerSlide = 4;
      let next = el.nextElementSibling;
      for (var i = 1; i < minPerSlide; i++) {
        if (!next) {
          // wrap carousel by using first child
          next = items[0];
        }
        let cloneChild = next.cloneNode(true);
        el.appendChild(cloneChild.children[0]);
        next = next.nextElementSibling;
      }
    });
  }, []);
  return (
    <>
      {/* Top Section */}
      <div className="container-fluid top-section">
        <div className="col-md-12">
          <h1 className="text-center title-text mt-md-4">
            The Perfect gift Solution, <br />
            <span className="text-green">eGift Card</span>
          </h1>
          <h2 className="subtitle no-mb">
            We are on a mission to make gifting fun, personalized <br />
            and simple with e-gift card.
          </h2>
          <div className="text-center">
            <Link
              to="/"
              className="btn btn-success btn-sm py-md-3 px-md-4 btn-radius"
            >
              Buy E-gift Card
            </Link>
          </div>
        </div>
        <div className="container px-2">
          <div className="row mt-14 desc-border">
            <div className="col-md-12 py-3 text-center">
              Prime is the first eGift card platform in Ghana, providing the
              opportunity for merchants to offer their valued customers an
              alternative way to send gifts. Empowering shoppers to give and
              redeem gift cards, helps stores to increase revenue, acquire new
              consumers, and obtain additional brand recognition.
            </div>
          </div>
        </div>
      </div>
      {/* Shop Over 500+ eGift Cards Section */}
      <div className="container-fluid mt-7 bg-white">
        <div className="row">
          <div className="col-md-12 mt-5">
            <div className="text-center">
              <h2 className="sub-heading">
                Shop <span className="text-green">500+</span> eGift cards
              </h2>

              <p className="sub-text">
                We have got eGift cards for every occasion, from Baby Showers,
                Birthdays, Mother’s Day, <br />
                Anniversaries, Fathers Day, Christmas, Weddings and many more.
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="col-md-12">
            <div className="row">
              <img
                src="/assets/images/shop-egift.png"
                width="100%"
                alt="shop-egift"
              />
            </div>
          </div>
        </div>
      </div>
      {/* Celebrate Friends and Family Section */}
      <div className="container-fluid mt-5 pb-5 img-background">
        <div className="row">
          <div className="text-center sub-heading">
            Celebrate friends and Family
          </div>
          <p className="sub-text">
            Supprise friends and family with e-gift card, that gives them the
            liberty to choose what to buy on their....
          </p>
        </div>

        <div className="container">
          <div className="row">
            <img
              alt="shop-egift"
              src="/assets/images/celebrate.png"
              className="img-responsive py-3"
            />
          </div>
        </div>
      </div>

      {/* Prime EGift Card */}
      <div className="container-fluid py-5 bg-egift">
        <div className="container">
          <div className="row justify-content-center align-items-center padding-row">
            <div className="col">
              <div className="sub-heading2 text-left">
                Prime
                <span className="text-green"> eGift Card</span>
              </div>
              <h2 className="sub-text text-left">
                Prime Universal eGift card, gives the recepient the liberty to
                shop from any prime participating shop and prime marketplace.
              </h2>
            </div>
            <div className="col">
              <div className="float-end">
                <img
                  alt="shop-egift"
                  src="/assets/images/cards.png"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* How it Works */}
      <div className="container mt-5">
        <div className="row">
          <div className="text-center sub-heading">How it works</div>
          <p className="sub-text">
            Sending e-gift cards to friends and family is simple and easy
          </p>
        </div>

        <div className="container">
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="card card-shadow mb-3">
                <div className="card-body card-min-height">
                  <div>
                    <i className="fa-regular fa-credit-card fa-size circle-icon"></i>
                  </div>
                  <div className="subtitle2 text-left mt-3">
                    <b>Select the Card</b>
                  </div>
                  <div className="sub-text text-left mt-2">
                    Select a specific card from our catalog of 200+ brands, a
                    Prime eGift Card that can be used at any merchant
                    participating store, or your recipients can swap for one or
                    more of any of our cards.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card-shadow mb-3">
                <div className="card-body card-min-height">
                  <div>
                    <i className="fa-regular fa-edit fa-size circle-icon circle-icon-blue"></i>
                  </div>
                  <div className="subtitle2 text-left mt-3">
                    <b>Personalize your gift</b>
                  </div>
                  <div className="sub-text text-left mt-2">
                    Create a bespoke gifting experience by adding a video, voice
                    or text message and a greeting card. The Prime eGift Card
                    can also be co-branded with your company logo or design of
                    choice.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card-shadow mb-3">
                <div className="card-body card-min-height">
                  <div>
                    <i className="fa-regular fa-credit-card fa-size circle-icon circle-icon-green"></i>
                  </div>
                  <div className="subtitle2 text-left mt-3">
                    <b>Deliver and track</b>
                  </div>
                  <div className="sub-text text-left mt-2">
                    Send your gift individually or in bulk via SMS, and choose
                    to send instantly or at a scheduled time. Track the delivery
                    status of every card sent in real time.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile App Features */}
      <div className="container-fluid mt-7 mobile-bg">
        <div className="container">
          <div className="row mt-3">
            <div className="text-center sub-heading mt-md-5 mt-4">
              Prime Moblie App<span className="text-green"> Features</span>
            </div>
          </div>
        </div>

        <div className="row mt-md-5 mt-2 mx-1">
          {/* <!-- Mobile App Carousel --> */}
          <div className="container-fluid text-center my-3">
            <div className="row mx-auto my-auto justify-content-center">
              <div
                id="recipeCarousel"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner" role="listbox">
                  <div className="carousel-item active">
                    <div className="col-md-3 col-4">
                      <div className="card mobile-card">
                        <div className="card-img">
                          <img
                            alt="shop-egift"
                            src="/assets/images/Market place 1.png"
                            className="img-fluid"
                          />
                        </div>
                        {/* <!-- <div className="card-img-overlay">Slide 1</div> --> */}
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="col-md-3 col-4">
                      <div className="card mobile-card">
                        <div className="card-img">
                          <img
                            alt="shop-egift"
                            src="/assets/images/Redeem options 1.png"
                            className="img-fluid"
                          />
                        </div>
                        {/* <!-- <div className="card-img-overlay">Slide 1</div> --> */}
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="col-md-3 col-4">
                      <div className="card mobile-card">
                        <div className="card-img">
                          <img
                            alt="shop-egift"
                            src="/assets/images/Request for fund 1.png"
                            className="img-fluid"
                          />
                        </div>
                        {/* <!-- <div className="card-img-overlay">Slide 1</div> --> */}
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="col-md-3 col-4">
                      <div className="card mobile-card">
                        <div className="card-img">
                          <img
                            alt="shop-egift"
                            src="/assets/images/Wallet 1.png"
                            className="img-fluid"
                          />
                        </div>
                        {/* <!-- <div className="card-img-overlay">Slide 1</div> --> */}
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="col-md-3 col-4">
                      <div className="card mobile-card">
                        <div className="card-img">
                          <img
                            alt="shop-egift"
                            src="/assets/images/Market place 1.png"
                            className="img-fluid"
                          />
                        </div>
                        {/* <!-- <div className="card-img-overlay">Slide 1</div> --> */}
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="col-md-3 col-4">
                      <div className="card mobile-card">
                        <div className="card-img">
                          <img
                            alt="shop-egift"
                            src="/assets/images/Market place 1.png"
                            className="img-fluid"
                          />
                        </div>
                        {/* <!-- <div className="card-img-overlay">Slide 1</div> --> */}
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  className="carousel-control-prev bg-transparent w-aut"
                  href="#recipeCarousel"
                  role="button"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                </a>
                <a
                  className="carousel-control-next bg-transparent w-aut"
                  href="#recipeCarousel"
                  role="button"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                </a>
              </div>
            </div>
          </div>
          {/* <!-- End of Mobile App Carousel --> */}
        </div>
      </div>
      <div className="mt-5">
        <h3 className="sub-heading text-center">Shop from our merchants</h3>
      </div>
      <section className="mt-5 container p-3">
        <Slider {...settings}>
          <div className="pr-1">
            <img
              src="/assets/images/Merchants/1.png"
              alt=""
              width="50%"
              height="50%"
            />
          </div>
          <div className="pr-1">
            <img
              src="/assets/images/Merchants/2.jpeg"
              alt=""
              width="50%"
              height="50%"
            />
          </div>
          <div className="pr-1">
            <img
              src="/assets/images/Merchants/3.jpeg"
              alt=""
              width="50%"
              height="50%"
            />
          </div>
          <div className="pr-1">
            <img
              src="/assets/images/Merchants/5.png"
              alt=""
              width="50%"
              height="50%"
            />
          </div>
          <div className="pr-1">
            <img
              src="/assets/images/Merchants/8.png"
              alt=""
              width="50%"
              height="50%"
            />
          </div>
          <div className="pr-1">
            <img
              src="/assets/images/Merchants/13.png"
              alt=""
              width="50%"
              height="50%"
            />
          </div>
          <div className="pr-2">
            <img
              src="/assets/images/Merchants/14.jpeg"
              alt=""
              width="50%"
              height="50%"
            />
          </div>
          <div className="pr-1">
            <img
              src="/assets/images/Merchants/10.png"
              alt=""
              width="50%"
              height="50%"
            />
          </div>
          <div className="pr-1">
            <img
              src="/assets/images/Merchants/11.jpeg"
              alt=""
              width="50%"
              height="50%"
            />
          </div>

          <div className="p-2">
            <img
              src="/assets/images/Merchants/9.png"
              alt=""
              width="100%"
              // height="80%"
            />
          </div>
         
          <div className="p-2">
            <img
              src="/assets/images/Merchants/4.png"
              alt=""
              width="80%"
              // height="50%"
            />
          </div>
          
          <div className="p-2">
            <img
              src="/assets/images/Merchants/6.png"
              alt=""
              width="50%"
              height="50%"
            />
          </div>
          <div className="p-2">
            <img
              src="/assets/images/Merchants/7.jpeg"
              alt=""
              width="80%"
              // height="50%"
            />
          </div>
          
          
          
          <div className="p-2">
            <img
              src="/assets/images/Merchants/12.png"
              alt=""
              width="80%"
              // height="50%"
            />
          </div>
         
        </Slider>
      </section>
      
      <Subscribe/>

      <Footer />
    </>
  );
};

export default Index;
