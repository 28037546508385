const BuyGiftCards = () => {
  return (
    <div
      className="card card-back tab-pane fade mb-5"
      id="pills-buy"
      role="tabpanel"
      aria-labelledby="pills-buy-tab"
    >
      <div className="col-lg-12">
        <div className="row">
          <div className="gift-text">
            <h4>
              At the moment, card purchase is done only on android, ios platform
              and a web app. These mobile apps can be downloaded by opening the
              link below on your mobile supported device. Link:
              <a
                href="https://play.google.com/store/apps/details?id=com.jackbuild.prime.customer"
                target="_blank"
                className="text-success ms-2"
                rel="noreferrer"
              >
                Prime E-Gift Mobile App
              </a>
              .
            </h4>
            <h4 className="mt-5">On your Mobile App:</h4>
            <ul className="ul-text-color">
              <li>Login into the app</li>
              <li>
                Select a gift card of your choice from your preferred merchant
              </li>
              <li>
                Select the amount you prefer to buy and add/save to your cart.
                You can select multiple amounts
              </li>
              <li>
                After saving to your cart, you can now go to your cart to
                checkout and make payment of cards.
              </li>
            </ul>
            <h3 className="mt-5 buying-font mb-4">
              Buying e-gift card for yourself
            </h3>
            <div className="col-lg-12">
              <div className="row justify-content-between align-items-center mb-5">
                <div className="col-lg-2 col-6">
                  <div className="step-cover">
                    <img
                      src="/assets/images/login.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="text-center mt-2">
                    <h3>Step 1</h3>
                  </div>
                  <div className="line-cover d-lg-flex justify-content-between d-none">
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                  </div>
                </div>

                <div className="col-lg-2 col-6">
                  <div className="step-cover">
                    <img
                      src="/assets/images/select-card.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="text-center mt-2">
                    <h3>Step 2</h3>
                  </div>
                  <div className="line-cover d-lg-flex justify-content-between d-none">
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                  </div>
                </div>
                <div className="col-lg-2 col-6">
                  <div className="step-cover">
                    <img
                      src="/assets/images/select-amount.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="text-center mt-2">
                    <h3>Step 3</h3>
                  </div>
                  <div className="line-cover d-lg-flex justify-content-between d-none">
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                  </div>
                </div>

                <div className="col-lg-2 col-6">
                  <div className="step-cover">
                    <img
                      src="/assets/images/pay.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="text-center mt-2">
                    <h3>Step 4</h3>
                  </div>
                  <div className="line-cover d-lg-flex justify-content-between d-none">
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                  </div>
                </div>
                <div className="col-lg-2 col-6">
                  <div className="step-cover">
                    <img
                      src="/assets/images/card-in-wallet.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="text-center mt-2">
                    <h3>Step 5</h3>
                  </div>
                  <div className="line-cover d-lg-flex justify-content-between d-none">
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                  </div>
                </div>
              </div>
            </div>

            <h3 className="mt-5 buying-font mb-3 mt-buy">
              Buying e-gift card for others
            </h3>
            <div className="col-lg-12">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-2 col-6">
                  <div className="step-cover">
                    <img
                      src="/assets/images/login.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="text-center mt-2">
                    <h3>Step 1</h3>
                  </div>
                </div>
                <div className="col-lg-1 d-none d-lg-block">
                  <div className="line-cover d-flex justify-content-between">
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                  </div>
                </div>

                <div className="col-lg-2 col-6">
                  <div className="step-cover">
                    <img
                      src="/assets/images/select-card.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="text-center mt-2">
                    <h3>Step 2</h3>
                  </div>
                </div>
                <div className="col-lg-1 d-none d-lg-block">
                  <div className="line-cover d-flex justify-content-between">
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                  </div>
                </div>
                <div className="col-lg-2 col-6">
                  <div className="step-cover">
                    <img
                      src="/assets/images/select-amount.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="text-center mt-2">
                    <h3>Step 3</h3>
                  </div>
                </div>
                <div className="col-lg-1 d-none d-lg-block">
                  <div className="line-cover d-flex justify-content-between">
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                  </div>
                </div>
                <div className="col-lg-2 col-6">
                  <div className="step-cover">
                    <img
                      src="/assets/images/other.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="text-center mt-2">
                    <h3>Step 4</h3>
                  </div>
                </div>
                <div className="col-lg-2 col-6 mt-lg-5">
                  <div className="step-cover">
                    <img
                      src="/assets/images/select-date-and-time.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="text-center mt-2">
                    <h3>Step 5</h3>
                  </div>
                </div>
                <div className="col-lg-1 d-none d-lg-block">
                  <div className="line-cover d-flex justify-content-between">
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                  </div>
                </div>
                <div className="col-lg-2 col-6 mt-lg-5">
                  <div className="step-cover">
                    <img
                      src="/assets/images/add-message.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="text-center mt-2">
                    <h3>Step 6</h3>
                  </div>
                </div>
                <div className="col-lg-1 d-none d-lg-block">
                  <div className="line-cover d-flex justify-content-between">
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                  </div>
                </div>
                <div className="col-lg-2 col-6 mt-lg-5">
                  <div className="step-cover">
                    <img
                      src="/assets/images/pay.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="text-center mt-2">
                    <h3>Step 7</h3>
                  </div>
                </div>
                <div className="col-lg-1 d-none d-lg-block">
                  <div className="line-cover d-flex justify-content-between">
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                    <div className="short-line"></div>
                  </div>
                </div>
                <div className="col-lg-2 col-6 mt-lg-5">
                  <div className="step-cover">
                    <img
                      src="/assets/images/delivered.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="text-center mt-2">
                    <h3>Step 8</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <h3 className="mt-5 buying-font mb-3 mt-buy">
                    Regifting e-gift Card
                  </h3>
                  <ul className="ul-text-color ">
                    <li>Select an un-used e-gift card from your wallet</li>
                    <li>Select recipient number</li>
                    <li>Add a message</li>
                    <li>Select the date and time to deliver the card</li>
                    <li>and Send</li>
                  </ul>
                </div>
                <div className="col-lg-8">
                  <img
                    src="/assets/images/regiftcard.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BuyGiftCards;
