import React, { useEffect, useState } from "react";
import OtpField from "react-otp-field";
import { useParams } from "react-router-dom";

function CreditAccountPage() {
  const initialUserInput = {
    amount: 0,
    phone_number: "",
  };
  const { code } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [accountCode, setAccountCode] = useState("");
  const [userInput, setUserInput] = useState(initialUserInput);

  useEffect(() => {
    if (code) {
      setAccountCode(code);
    } else {
      setAccountCode("");
    }
  }, [code]);

  console.log("ass", accountCode);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };


  return (
    <div className="container-fluid">
      <div className="col-md-12">
        <div className="container mb-5 mb-md-0">
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col-lg-6">
              <img src="/assets/images/bg.svg" className="img-fluid" />
            </div>
            <div className="col-lg-6">
              <div className="card p-md-3 py-3">
                <div className="row">
                  <div className="pad-sm1">
                    <h4>16 digit code sent to your number</h4>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="d-flex pad-sm1">
                    <OtpField
                      value={accountCode}
                      onChange={setAccountCode}
                      numInputs={16}
                      onChangeRegex={/^([0-9]{0,})$/}
                      isTypeNumber
                      inputProps={{
                        className: "form-control number-textbox2 mt-3 me-2",
                        disabled: false,
                      }}
                    />
                  </div>
                  <small className="text-danger ml-0">{errorMessage}</small>
                </div>
                <div className="row">
                  <div className="pad-sm1">
                    <h4>Enter Amount in Ghana Cedis</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="pad-sm1">
                    <div className="form-group d-flex">
                      <input
                        className="form-control number-textbox"
                        type="number"
                        name="amount"
                        value={userInput?.amount}
                        onChange={handleUserInput}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="pad-sm1">
                    <h4>Enter Your Phone Number</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="pad-sm1">
                    <div className="form-group d-flex">
                      <input
                        className="form-control"
                        type="number"
                        name="phone_number"
                        value={userInput?.phone_number}
                        onChange={handleUserInput}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="pad-sm1">
                    <button className="btn btn-success w-100">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditAccountPage;
