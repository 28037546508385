import Product from "./Product";

const BestSelling = () => {
  return (
    <>
      <div className="container">
        <div className="row mt-3 ml-2">
          <div className="p-2">
            <div className="text-dark text-left text-left1 subtitle">
              Best Selling
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3 mt-2">
            <Product
              image="/assets/images/best-selling1.png"
              title="Product Name"
              location="East Lagon,Accra"
              price="100"
            />
          </div>
          <div className="col-md-3 mt-2">
            <Product
              image="/assets/images/best-selling2.png"
              title="Product Name"
              location="East Lagon,Accra"
              price="100"
            />
          </div>
          <div className="col-md-3 mt-2">
            <Product
              image="/assets/images/best-selling3.png"
              title="Product Name"
              location="East Lagon,Accra"
              price="100"
            />
          </div>
          <div className="col-md-3 mt-2">
            <Product
              image="/assets/images/best-selling4.png"
              title="Product Name"
              location="East Lagon,Accra"
              price="100"
            />
          </div>

          <div className="col-md-3 mt-2">
            <Product
              image="/assets/images/best-selling1.png"
              title="Product Name"
              location="East Lagon,Accra"
              price="100"
            />
          </div>
          <div className="col-md-3 mt-2">
            <Product
              image="/assets/images/best-selling2.png"
              title="Product Name"
              location="East Lagon,Accra"
              price="100"
            />
          </div>
          <div className="col-md-3 mt-2">
            <Product
              image="/assets/images/best-selling3.png"
              title="Product Name"
              location="East Lagon,Accra"
              price="100"
            />
          </div>
          <div className="col-md-3 mt-2">
            <Product
              image="/assets/images/best-selling4.png"
              title="Product Name"
              location="East Lagon,Accra"
              price="100"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BestSelling;
