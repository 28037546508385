import Footer from "../guest/partials/Footer";

const PrimePlusPrivacy = () => {
  return (
    <>
      <section>
        <div className="container p-4">
          <div className="WordSection1">
            <p
              className="MsoNormal sub-heading"
              style={{
                marginTop: "6.0pt",
                marginRight: "0cm",
                marginBottom: "6.0pt",
                marginLeft: "0cm",
              }}
            >
              <span
                lang="EN-US"
                style={{
                  fontSize: "2rem",
                  color: "#333333",
                  letterSpacing: ".75pt",
                }}
              >
                Privacy Policy for Prime E-Gift Plus
              </span>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Last Updated: 14/08/2023
                <br />
                <br />
              </span>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                This Privacy Policy outlines the practices of Prime E-Gift Plus
                ("we", "us", or "our") regarding the collection, use, and
                disclosure of personal information when you use our mobile
                application,
              </span>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#1E95D8" }}
              >
                Prime E-Gift Plus
              </span>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                ("the App"). This policy applies to individuals and businesses
                that use the App for purchasing gift cards in bulk for clients
                or employees. By accessing or using the App, you agree to the
                terms outlined in this Privacy Policy.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "0cm",
              }}
            >
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  *Information Collection and Use*
                </span>
              </b>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                We collect and use personal information for the purpose of
                providing a secure and efficient experience when using the App.
                The types of information we collect may include:
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "22.5pt",
                textIndent: "-18.0pt",
              }}
            >
              <span
                lang="EN-US"
                style={{
                  fontSize: "10.0pt",
                  color: "#1E95D8",
                  textTransform: "uppercase",
                  letterSpacing: ".75pt",
                }}
              >
                ·
                <span style={{ fontSize: "7.0pt" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  1. Account Information
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                When you log in to the App, we collect your phone number, email
                address, and password or PIN for authentication purposes.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "22.5pt",
                textIndent: "-18.0pt",
              }}
            >
              <span
                lang="EN-US"
                style={{
                  fontSize: "10.0pt",
                  color: "#1E95D8",
                  textTransform: "uppercase",
                  letterSpacing: ".75pt",
                }}
              >
                ·
                <span style={{ fontSize: "7.0pt" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  2. KYC Information
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Users who have been verified through our Know Your Customer
                (KYC) process on our web portal will have their name, address,
                identification documents, and other relevant information stored
                securely for compliance and verification purposes.{" "}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "22.5pt",
                textIndent: "-18.0pt",
              }}
            >
              <span
                lang="EN-US"
                style={{
                  fontSize: "10.0pt",
                  color: "#1E95D8",
                  textTransform: "uppercase",
                  letterSpacing: ".75pt",
                }}
              >
                ·
                <span style={{ fontSize: "7.0pt" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  3. Recipient Information
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                In the process of sending gift cards, you may be required to
                provide recipient phone numbers or email addresses to facilitate
                the delivery of the gift cards.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "0cm",
              }}
            >
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  *Information Sharing and Disclosure*
                </span>
              </b>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                We do not sell, rent, or share your personal information with
                third parties for marketing purposes. However, we may share your
                information under the following circumstances:
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "22.5pt",
                textIndent: "-18.0pt",
              }}
            >
              <span
                lang="EN-US"
                style={{
                  fontSize: "10.0pt",
                  color: "#1E95D8",
                  textTransform: "uppercase",
                  letterSpacing: ".75pt",
                }}
              >
                ·
                <span style={{ fontSize: "7.0pt" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  1. Service Providers
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                We may share your personal information with trusted third-party
                service providers who assist us in providing the App's features
                and services. These providers are obligated to keep your
                information confidential and only use it for specific purposes.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "22.5pt",
                textIndent: "-18.0pt",
              }}
            >
              <span
                lang="EN-US"
                style={{
                  fontSize: "10.0pt",
                  color: "#1E95D8",
                  textTransform: "uppercase",
                  letterSpacing: ".75pt",
                }}
              >
                ·
                <span style={{ fontSize: "7.0pt" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  2. Legal Compliance
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                We may disclose your information if required to do so by law or
                in response to valid legal requests, such as subpoenas, court
                orders, or government investigations.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "0cm",
              }}
            >
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  *Security*
                </span>
              </b>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                We take the security of your personal information seriously. We
                use industry-standard security measures to protect your data
                from unauthorized access, disclosure, alteration, or
                destruction.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "0cm",
              }}
            >
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  *Data Retention*
                </span>
              </b>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                We retain your personal information for as long as necessary to
                fulfill the purposes outlined in this Privacy Policy, unless a
                longer retention period is required or permitted by law.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "0cm",
              }}
            >
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  *Your Choices*
                </span>
              </b>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                You have the right to review, update, or delete your personal
                information from the App. To do so, please contact our customer
                support team. You may also modify your communication preferences
                or opt-out of receiving certain communications from us.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "0cm",
              }}
            >
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  *Children's Privacy*
                </span>
              </b>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                The App is not intended for use by individuals under the age of
                13. We do not knowingly collect personal information from
                children under 13. If you believe that a child under 13 has
                provided us with personal information, please contact us
                immediately.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "0cm",
              }}
            >
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  *Changes to this Privacy Policy*
                </span>
              </b>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                We reserve the right to update this Privacy Policy from time to
                time. We will notify you of any material changes by posting the
                updated policy on the App and indicating the date of the last
                update. Your continued use of the App after such changes will
                constitute your acceptance of the updated Privacy Policy.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "0cm",
              }}
            >
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  *Contact Us*
                </span>
              </b>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                If you have any questions or concerns regarding this Privacy
                Policy, please contact us at 
                <span lang="EN-US">
                  <a href="mailto:info@primergift.com">
                    <span style={{ fontSize: "13.5pt" }} className="ms-1">
                      info@primergift.com
                    </span>
                  </a>
                </span>
              </span>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                By using the Prime E-Gift Plus mobile application, you
                acknowledge that you have read and understood this Privacy
                Policy and agree to the collection, use, and disclosure of your
                personal information as described herein.
              </span>
            </p>

            <p className="MsoNormal">
              <span lang="EN-US">&nbsp;</span>
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PrimePlusPrivacy;
