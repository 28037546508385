import { Link, useLocation, useParams } from "react-router-dom";

const Nav = ({ visible, setVisible }) => {
  const location = useLocation();
  const { code } = useParams();
  const navList = [
    {
      name: "Home",
      to: "/home",
    },
    {
      name: "Shop egift card",
      to: "/",
    },
    // {
    //   name: '',
    //   to: "/market"
    // },
    {
      name: "Prime Business",
      to: "/business",
    },
    {
      name: "Merchants",
      to: "/merchants",
    },
    // {
    //   name: "Credit",
    //   to: code ? `/credit/${code}` : "/credit",
    // },

    {
      name: "Affiliate",
      to: "/affiliate",
    },
    {
      name: "About us",
      to: "/about",
    },
    {
      name: "Customer Care",
      to: "/customer-care",
    },
    {
      name: "Prime E-Gift Usage",
      to: "/prime-usage",
    },
    {
      name: "Account Management",
      to: "/account-management",
    },
  ];
  return (
    <>
      <div className="container nav-padding">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container">
            <a className="navbar-brand" href="#">
              <Link to="/">
                <img
                  src="/assets/images/logo.png"
                  className="logo-img"
                  alt="logo"
                />
              </Link>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav ms-auto me-auto  mb-2 mb-lg-0">
                {navList.map((el, index) => (
                  <li
                    className={`nav-item ${
                      location.pathname === el.to && "active"
                    }`}
                    key={index}
                  >
                    <Link to={el.to} className="nav-link">
                      {el.name}
                    </Link>
                  </li>
                ))}
              </ul>

              {/* <span className="navbar-text">
                <Link
                  to="/cart"
                  className="btn btn-success py-2 btn-radius create-btn text-white"
                >
                  View Cart
                </Link>
              </span> */}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Nav;
