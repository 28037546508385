import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import ShopLoginDialog from "./ShopLoginDialog";
import { Skeleton } from "@mui/material";

const OrderPreview = ({ userInput, card }) => {
  const [cart, setCart] = useState();
  const [isCardsLoaded, setIsCardsLoaded] = useState(false);

  useEffect(() => {
    localStorage.removeItem("token");
    axios
      .get("/prime.sika/v1/carts/customer/current-cart")
      .then((res) => {
        if (res.data.success === true && res.data.status === 200) {
          setCart(res.data.data.cart);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  //  console.log(cart);

  const removeItem = (card_id) => {
    let data = {
      cart_id: cart?.cart_id,
    };

    axios
      .put(`/prime.sika/v1/carts/customer/remove/${card_id}`, data)
      .then((res) => {
        if (res.data.success === true && res.data.status === 200) {
          setCart(res.data.data.cart);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const clearCart = () => {
    let data = {
      cart_id: cart?.cart_id,
    };
    axios
      .post("/prime.sika/v1/carts/customer/clear", data)
      .then((res) => {
        if (res.data.success === true && res.data.status === 201) {
          setCart(res.data?.data?.cart);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const makePayment = () => {
    var data;
    userInput.message
      ? (data = {
          telephone: userInput.phone,
          use_prime_wallet: false,
          cart_id: cart?.cart_id,
          payment_to: "OTHERS",
          message: userInput.message,
        })
      : (data = {
          telephone: userInput.phone,
          use_prime_wallet: false,
          cart_id: cart?.cart_id,
          payment_to: "OTHERS",
          // message: userInput.message,
        });

    axios
      .post("/prime.sika/v1/payments/customer", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status === 201 && res.data.success === true) {
          console.log(res.data.data.payment_url);
          window.location.replace(res.data.data.payment_url);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  var rows = cart?.cards?.map((item, index) => (
    <tr key={index}>
      <td width="30%">
        <img src={item.image} alt="Card" width="70%" style={{ borderRadius:"10px" }} />
      </td>
      <td>{item.title}</td>
      <td>¢ {item.amount}</td>
      <td>
        <input
          className="btn btn-danger"
          type="button"
          onClick={(e) => {
            e.target.value = "Please wait...";
            removeItem(item.item_id);
          }}
          value="Remove"
        />
      </td>
    </tr>
  ));

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="row">
            <div className="col-md-11">
              {cart?.cards?.[0]?.image ? (
                <img
                  src={cart?.cards[0]?.image}
                  alt="Product"
                  width="100%"
                  style={{ borderRadius: "10px" }}
                />
              ) : (
                <Skeleton
                  variant="rectangular"
                  width={350}
                  height={230}
                  style={{ borderRadius: "10px" }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-md-8 mt-2">
          <div className="container">
            <div className="d-flex flex-row justify-content-between">
              <div className="p-0">
                <h3 className="product-detail-title">
                  {cart?.cards ? (
                    cart?.cards[0]?.client_name
                  ) : (
                    <Skeleton variant="rectangular" width={300} height={25} />
                  )}
                </h3>
              </div>
              <div className="p-2">
                <div className="total-container">
                  <div className="shop-card-text-desc">
                    Total:{" "}
                    <span className="total-amount">¢ {cart?.total_amount}</span>
                  </div>
                </div>
              </div>
            </div>

            {cart ? (
              cart?.cards?.length > 0 ? (
                <div className="mt-3">
                  <h3 className="text-faint text-left">
                    Cards Total:{" "}
                    <span className="text-success">¢ {cart?.net_amount}</span>
                  </h3>
                  <h3 className="text-faint text-left">
                    Prime Fees:{" "}
                    <span className="text-success">¢ {cart?.fees}</span>
                  </h3>
                </div>
              ) : (
                <h4 className="product-detail-title">Your Cart is Empty</h4>
              )
            ) : (
              <h4 className="product-detail-title">
                <Skeleton variant="rectangular" width={300} height={25} />
              </h4>
            )}

            <div className="row">
              <div className="mt-5">
                <div className="col-md-7 d-flex justify-content-start">
                  {cart?.cards?.length > 0 ? (
                    <>
                      <div className="p-0">
                        <button
                          className="btn btn-dark px-5 py-2 me-2"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#make_payment"
                        >
                          Make Payment
                        </button>
                      </div>
                      <div
                        className="modal fade"
                        id="make_payment"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabIndex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                      >
                        <ShopLoginDialog makePayment={makePayment} />
                      </div>
                      <div className="p-0">
                        <Link to="/" className="btn btn-success px-5 py-2 me-2">
                          Shop Gift Cards
                        </Link>
                      </div>
                    </>
                  ) : (
                    <div className="p-0">
                      <Link to="/" className="btn btn-success px-5 py-2 me-2">
                        Shop Gift Cards
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-12">
          <div className="d-flex flex-row justify-content-between">
            <div className="mb-3">
              <h3 className="my-auto">Order Preview</h3>
            </div>
            <div className="mb-3">
              <button
                className="btn btn-danger px-5 py-2 me-2"
                onClick={clearCart}
              >
                Clear
              </button>
            </div>
          </div>
          <div className="card-table">
            <div className="card-body booking_card">
              <div className="table-responsive">
                <table className="datatable table table-stripped table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th>Card</th>
                      <th>Title</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5 mb-5">
        <div className="col-md-4">
          <div>
            <h4>Merchant Info</h4>
          </div>
          <div className="mt-3">
            <h3 className="text-faint text-left text-faint-height">
              {card?.description}
            </h3>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex justify-content-between mx-5">
            <div className="ms-5">
              <div>
                <h4>Location</h4>
              </div>
              <div className="mt-3">
                <h3 className="text-faint text-left text-faint-height">
                  {card?.client?.location}
                </h3>
              </div>
            </div>
            <div className="me-5">
              <div>
                <h4>Contact Info</h4>
              </div>
              <div className="mt-3">
                <h3 className="text-faint text-left text-faint-height">
                  {card?.client_telephone}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPreview;
