import ReactPaginate from "react-paginate"
const PagePagination = ({ totalPage, setCurrentPage }) => {

    return (
        <>
            <div className="mt-2">
                <ReactPaginate
                    previousLabel={'prev'}
                    nextLabel={'next'}
                    breakLabel={'..'}
                    pageCount={totalPage}
                    marginPagesDisplayed={2}
                    onPageChange={(e) => setCurrentPage(e.selected + 1)}
                    containerClassName={'pagination justify-content-end'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </div>
        </>
    )
}
export default PagePagination