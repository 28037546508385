import axios from "axios";
import React, { useEffect, useState } from "react";
import OtpField from "react-otp-field";
import { ClipLoader } from "react-spinners";
import { toast } from "sonner";

function VerifyCode({
  handleResendSubmit,
  setCurrentScreen,
  resending,
  setOtpDigit,
  phoneNumber
}) {
  const [otpCode, setOTPCode] = useState("");
  const [isLoading, setLoading] = useState(false);

  


  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      otp: otpCode,
      telephone: phoneNumber
    };
    setLoading(true);
    axios
      .post("/prime.sika/v1/customers/verify-user-otp", data)
      .then(async (res) => {
        setLoading(false);
        let msg = res.data.data.message;
        console.log("data", res);
        setOtpDigit(res.data.data.new_code)
        toast.success(msg, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setCurrentScreen(3);
      })
      .catch((err) => {
        console.log(err.response.data?.error_msg);
        setLoading(false);
        toast.error(err.response.data?.error_msg, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="card card-shadow1">
        <h4 className="phone-text">Enter 6 digit code sent to your phone</h4>
        <div className="form-group d-flex justify-content-between align-items-center">
          <OtpField
            value={otpCode}
            onChange={setOTPCode}
            numInputs={6}
            onChangeRegex={/^([0-9]{0,})$/}
            isTypeNumber
            inputProps={{
              className: "form-control number-textbox3 mt-3 me-2",
              disabled: false,
            }}
          />
        </div>
        <h4 className="otp-font mt-3">
          Didn't receive otp?{" "}
          <span className="text-success" onClick={handleResendSubmit}>
            {resending ? "Resending..." : "Resend"}
          </span>
        </h4>
        <div className="row mt-4">
          <div className="pad-sm">
            <button type="submit" className="btn btn-danger w-100" disabled={isLoading}>
              {isLoading ? (
                <div className="d-flex align-items-center justify-content-center gap-2">
                  <ClipLoader
                    color={"#fff"}
                    loading={true}
                    size={18}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  <h4 className="cont-font">Continuing</h4>
                </div>
              ) : (
                <h4 className="cont-font">Continue</h4>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default VerifyCode;
