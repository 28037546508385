const Subscribe = () => {
  return (
    <div className="container mt-5">
      <div className="bg-subscribe-height">
        <div className="bg-subscribe">
          <form action="">
            <div className="row align-content-center justify-content-center row-padding-top">
              <div className="text-center text-white subscribe-text">
                <label htmlFor="subscribeInput">
                  {" "}
                  Subscribe to our newsletter
                </label>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control textbox-font"
                  id="subscribeInput"
                  placeholder="Enter your email here"
                />
              </div>
              <div className="col-md-2 col-2">
                <input
                  type="button"
                  className="btn btn-warning textbox-font1 text-white"
                  value="Subscribe"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
