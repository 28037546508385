import axios from "axios";
import { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import OtpField from "react-otp-field";
import Swal from "sweetalert2";

const RedeemCardModal = ({
  items,
  handleRedeemCardModalClose,
  showRedeemCardModal,
  currentScreen,
  setCurrentScreen,
  sdc,
  amt,
  val,
  setVal,
  otpCode,
  setOtpCode,
  cardCode,
  setCardCode,
}) => {
  const [verificationCode, setVerificationCode] = useState(sdc);
  const [isloading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [resending, setResending] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [checked, setChecked] = useState({
    code: "",
  });

  const handleChecked = (e) => {
    setChecked((checked) => ({
      ...checked,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCodeVerification = (e) => {
    var mer_code;
    if (items?.branches.length === 0) {
      mer_code = items?.code;
    } else {
      mer_code = checked?.code;
    }
    var card_code;
    if (sdc) {
      card_code = verificationCode;
    } else {
      card_code = cardCode;
    }
    setSubmitting(true);
    if (card_code.length !== 16) {
      setSubmitting(false);
      setErrorMessage("Invalid Card Code Entered");
    } else if (sdc && otpCode.length !== 6) {
      setSubmitting(false);
      Swal.fire({
        title: "Error",
        text: "Invalid Verification Code",
        icon: "error",
        button: "OK",
      });
    } else if (currentScreen === 3 && otpCode.length !== 6) {
      setSubmitting(false);
      Swal.fire({
        title: "Error",
        text: "Invalid Verification Code",
        icon: "error",
        button: "OK",
      });
    } else if (val === "") {
      setSubmitting(false);
      Swal.fire({
        title: "Error",
        text: "Enter Amount",
        icon: "error",
        button: "OK",
      });
    } else {
      setSubmitting(true);
      axios
        .post("/prime.sika/v1/customers/site-redemption", {
          merchant_code: mer_code,
          amount: val,
          account_code: card_code,
          confirmation_code: otpCode,
        })
        .then((res) => {
          Swal.fire({
            title: "Success",
            text: res.data.data.account.message,
            icon: "success",
            button: "OK",
          }).then((isOkay) => {
            if (isOkay) {
              handleRedeemCardModalClose();
            }
          });
          setSubmitting(false);
        })
        .catch((err) => {
          setSubmitting(false);
          console.log(err.response.data);
          Swal.fire({
            title: "Error",
            text: err.response.data.error_msg,
            icon: "error",
            button: "OK",
          });
        });
    }
  };

  const handleVerificationCode = () => {
    setLoading(true);
    if (cardCode.length !== 16) {
      setLoading(false);
      setErrorMessage("Invalid Verification Code Entered");
    } else if (val === "") {
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "Enter Amount",
        icon: "error",
        button: "OK",
      });
    } else {
      axios
        .get(`/prime.sika/v1/customers/${cardCode}/redemption-code`)
        .then((res) => {
          setLoading(false);

          setCurrentScreen(3);
        })
        .catch((err) => {
          setLoading(false);
          Swal.fire({
            title: "Error",
            text: err.response.data.error_msg,
            icon: "error",
            button: "OK",
          });
          console.log(err.response);
        });
    }
  };
  const handleMerchantCode = () => {
    if (checked.code.length === 0) {
      //   Swal;
      Swal.fire({
        title: "Error",
        text: "Please select a merchant to redeem from",
        icon: "error",
        button: "OK",
      });
    } else if (sdc) {
      Swal.fire({
        title: "Are you sure you want to redeem with this merchant?",
        text: "A 6 digit code will be sent to the owner of the card",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#00b17d",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          axios
            .get(`/prime.sika/v1/customers/${sdc}/redemption-code`)
            .then((res) => {
              Swal.fire({
                title: "Success",
                text: res.data.data.message,
                icon: "success",
                button: "OK",
              });
              setCurrentScreen(2);
            })
            .catch((err) => {
              Swal.fire({
                title: "Error",
                text: err.response.data.error_msg,
                icon: "error",
                button: "OK",
              });
              console.log(err.response);
            });
        }
      });
    } else {
      setCurrentScreen(2);
    }
  };
  const ResendOtp = () => {
    setResending(true);
    axios
      .get(`/prime.sika/v1/customers/${cardCode}/redemption-code`)
      .then((res) => {
        setResending(false);
        Swal.fire({
          title: "Success",
          text: res.data.data.message,
          icon: "success",
          button: "OK",
        });
      })
      .catch((err) => {
        setResending(false);
        Swal.fire({
          title: "Error",
          text: err.response.data.error_msg,
          icon: "error",
          button: "OK",
        });
        console.log(err.response);
      });
  };
  return (
    <Modal show={showRedeemCardModal} onHide={handleRedeemCardModalClose}>
      <Modal.Header closeButton>
        {/* <Modal.Title> */}
        <div className="d-flex justify-content-between w-100 redeem-text">
          <div>
            <h4 className="mt-3">Redeem Card</h4>
          </div>
        </div>
        {/* </Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        {currentScreen === 1 && (
          <>
            <div className="select-text">
              <h4>Please select the merchant you want to redeem card from</h4>
            </div>

            <ul className="ps-0 text-deco ms-2">
              <li className="mb-2">
                <label
                  htmlFor="main"
                  className="radio1 d-flex cur-pointer justify-content-between"
                >
                  <div className="d-block list-text">
                    <div className="d-flex mb-md-3">
                      <div className="merchant-logo1 me-3">
                        <img
                          src={
                            items?.logo_url === null
                              ? "/assets/images/default.png"
                              : items?.logo_url
                          }
                          alt=""
                        />
                      </div>
                      <h3 className="my-auto text-capitalize">{items?.name}</h3>
                    </div>
                    <div className="d-lg-flex d-block mb-md-3 justify-content-between">
                      <div className="d-flex sm-margin-top1">
                        <img
                          src="/assets/images/location.png"
                          alt=""
                          className="loc-img1"
                        />
                        <h6 className="ms-1">{items?.branch}</h6>
                      </div>
                      <div className="d-flex ms-lg-2 sm-margin-top1">
                        <img
                          src="/assets/images/call.png"
                          alt=""
                          className="loc-img1"
                        />
                        <h6 className="ms-1">{items?.telephone}</h6>
                      </div>
                    </div>
                  </div>
                  <input
                    type="radio"
                    name="code"
                    id="main"
                    value={items?.code}
                    className="radio__input mr-1"
                    onChange={handleChecked}
                  />
                  <div className="radio__radio1"></div>
                </label>
                <hr />
              </li>
              {items?.branches?.map((list, i) => (
                <li key={i} className="mb-2">
                  <label
                    htmlFor={`dd${i}r`}
                    className="radio1 d-flex cur-pointer justify-content-between"
                  >
                    <div className="d-block list-text">
                      <div className="d-flex mb-md-3">
                        <div className="merchant-logo1 me-3">
                          <img
                            src={
                              list?.logo_url === null
                                ? "/assets/images/default.png"
                                : items?.logo_url
                            }
                            alt=""
                          />
                        </div>
                        <h3 className="my-auto text-capitalize">
                          {list?.name}
                        </h3>
                      </div>
                      <div className="d-lg-flex d-block mb-md-3 justify-content-between">
                        <div className="d-flex sm-margin-top1">
                          <img
                            src="/assets/images/location.png"
                            alt=""
                            className="loc-img1"
                          />
                          <h6 className="ms-1">{list?.branch}</h6>
                        </div>
                        <div className="d-flex ms-lg-2 sm-margin-top1">
                          <img
                            src="/assets/images/call.png"
                            alt=""
                            className="loc-img1"
                          />
                          <h6 className="ms-1">{list?.telephone}</h6>
                        </div>
                      </div>
                    </div>
                    <input
                      type="radio"
                      name="code"
                      id={`dd${i}r`}
                      value={list?.code}
                      className="radio__input mr-1"
                      onChange={handleChecked}
                    />
                    <div className="radio__radio1"></div>
                  </label>
                  <hr />
                </li>
              ))}
            </ul>
            <div className="redeem-card-btn float-end">
              <button
                type="button"
                className="btn btn-success shop-now-font "
                onClick={() => handleMerchantCode()}
              >
                Continue
              </button>
            </div>
          </>
        )}
        {currentScreen === 2 && (
          <div>
            <div className="justify-content-between">
              <div className="col-lg-12 select-text">
                {items?.branches?.length !== 0 && (
                  <div className="mb-3">
                    <img
                      src="/assets/images/arrow-left1.png"
                      alt=""
                      className="cur-pointer"
                      onClick={() => setCurrentScreen(1)}
                    />
                  </div>
                )}
                <div className="row">
                  {amt && (
                    <div>
                      <h3 className="float-end">
                        Current Balance:{" "}
                        <span>₵ {parseFloat(amt).toFixed(2)}</span>
                      </h3>
                    </div>
                  )}
                </div>
                {sdc && (
                  <>
                    <div className="row">
                      <div className="pad-sm">
                        <h4>Enter the 6 digit code sent to your number</h4>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="d-flex justify-content-between pad-sm">
                        <OtpField
                          value={otpCode}
                          onChange={setOtpCode}
                          numInputs={6}
                          onChangeRegex={/^([0-9]{0,})$/}
                          isTypeNumber
                          inputProps={{
                            className:
                              "form-control number-textbox2 mt-1 me-2 justify-content-between",
                            disabled: false,
                          }}
                        />
                      </div>
                      <small className="text-danger ml-0">{errorMessage}</small>
                    </div>
                  </>
                )}
                <div className="row">
                  <div className="pad-sm">
                    <h4>Amount in Ghana Cedis</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="pad-sm">
                    <div className="form-group d-flex">
                      <input
                        className="form-control number-textbox"
                        type="number"
                        value={val}
                        onChange={(e) => setVal(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="pad-sm">
                    <h4>16 digit egift card number</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex justify-content-between pad-sm">
                    <OtpField
                      value={sdc ? verificationCode : cardCode}
                      onChange={sdc ? setVerificationCode : setCardCode}
                      numInputs={16}
                      onChangeRegex={/^([0-9]{0,})$/}
                      isTypeNumber
                      inputProps={{
                        className:
                          "form-control number-textbox1 mt-2 me-2 justify-content-between",
                        disabled: false,
                      }}
                    />
                  </div>
                  <small className="text-danger ml-0">{errorMessage}</small>
                </div>
              </div>
            </div>
            <div
              className={`${
                sdc ? "mt-5 d-flex justify-content-between" : "mt-5 float-end"
              }`}
            >
              {sdc && (
                <div>
                  {!resending && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={ResendOtp}
                    >
                      Resend Code
                    </button>
                  )}
                  {resending && (
                    <button className="btn btn-primary mr-1" disabled>
                      <i className="fas fa-spinner fa-spin mr-1"></i>
                      Resending...
                    </button>
                  )}
                </div>
              )}
              <div>
                {!isloading && (
                  <button
                    type="button"
                    className="btn btn-success redeem-btn"
                    onClick={
                      sdc ? handleCodeVerification : handleVerificationCode
                    }
                  >
                    {sdc ? "Submit" : "Continue"}
                  </button>
                )}
                {isloading && (
                  <button className="btn btn-success redeem-btn" disabled>
                    <i className="fas fa-spinner fa-spin mr-1"></i>loading...
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {currentScreen === 3 && (
          <div className="col-lg-12 col-12">
            <div>
              <div className="redeem-text pad-sm mb-3">
                <h4>
                  A 6-digit code has been sent to owner of this gift card,
                  Kindly enter it below.
                </h4>
              </div>
              <div className="d-flex justify-content-between pad-sm">
                <OtpField
                  value={otpCode}
                  onChange={setOtpCode}
                  numInputs={6}
                  onChangeRegex={/^([0-9]{0,})$/}
                  isTypeNumber
                  inputProps={{
                    className:
                      "form-control number-textbox2 mt-1 me-2 mb-3 justify-content-between",
                    disabled: false,
                  }}
                />
              </div>
              <small className="text-danger">{errorMessage}</small>
            </div>
            <div className="row">
              <div className="col-lg-7 col-7">
                {!resending && (
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={ResendOtp}
                  >
                    Resend Code
                  </button>
                )}
                {resending && (
                  <button className="btn btn-secondary" disabled>
                    <i className="fas fa-spinner fa-spin"></i>Resending...
                  </button>
                )}
              </div>

              <div className="col-lg-5 col-5">
                <div className="float-end">
                  {!submitting && (
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => handleCodeVerification()}
                    >
                      Submit
                    </button>
                  )}
                  {submitting && (
                    <button className="btn btn-success" disabled>
                      <i className="fas fa-spinner fa-spin mr-2"></i>loading...
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default RedeemCardModal;
